/* eslint-disable @typescript-eslint/no-redeclare */
export const LAYOUT_EXPAND = "layout/expand";
export type LAYOUT_EXPAND = typeof LAYOUT_EXPAND;

export const LAYOUT_COLLAPSED = "layout/collapsed";
export type LAYOUT_COLLAPSED = typeof LAYOUT_COLLAPSED;


export const LAYOUT_TOGGLE = "layout/toggle";
export type LAYOUT_TOGGLE = typeof LAYOUT_TOGGLE;

