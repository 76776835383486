/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { ApplicationState } from 'schema';

const WorkingHours = () => {
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);

	const isMobile = useMediaQuery({
		query: '(max-width: 500px)'
	})

	const lastParts = useSelector((state: ApplicationState) => state.shift.lastParts);

	function secondsToHms(d) {
		d = Number(d);
		let h = Math.floor(d / 3600);
		let m = Math.floor(d % 3600 / 60);
		let s = Math.floor(d % 3600 % 60);

		setHours(h === 0 ? 0 : h + 1);
		setMinutes(m === 0 ? 0 : m + 1);
		setSeconds(s === 0 ? 0 : s + 2);
	}

	useEffect(() => {
		const latestCheckin = lastParts[0];
		if (latestCheckin.checkout !== null) {
			setHours(0);
			setMinutes(0);
			setSeconds(0);
		} else {
			const now: number = parseFloat(moment().format("X"));
			secondsToHms(now - latestCheckin.checkin);
		}
	}, [])

	useEffect(() => {
		let myInterval = setInterval(() => {

			if (seconds < 59) {
				setSeconds(seconds + 1)
			} else {
				setSeconds(0);
				if (minutes < 59) {
					setMinutes(minutes + 1);
				} else {
					setMinutes(0);
					setHours(hours + 1)
				}
			}
		}, 1000)
		return () => {
			clearInterval(myInterval);
		};
	});
	return (
		<div style={{ fontSize: isMobile ? "12px" : "14px" }}>
			{hours !== 0 && `${hours} : `}{minutes < 10 ? `0${minutes}` : minutes} : {seconds < 10 ? `0${seconds}` : seconds}
		</div>
	);
};

export default WorkingHours;