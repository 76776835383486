import { Operator } from "schema";
import { HOST } from "config/api";
import request from "../request";

const EmployeeEndPoint = HOST;

export const getEmployees = () =>
	request<Operator[]>("GET", EmployeeEndPoint + "s");
export const create = (user: Operator & { password: string }) =>
	request<Operator>("POST", EmployeeEndPoint + "/new", { user });
export const deactiveEmployee = (username: string) =>
	request<Operator>("PUT", EmployeeEndPoint + "/delete/" + username);
