export const AUTH_LOADING = "auth/Loading";
export type AUTH_LOADING = typeof AUTH_LOADING;

export const AUTH_ERROR = "auth/Error";
export type AUTH_ERROR = typeof AUTH_ERROR;

export const AUTH_SUCCESS = "auth/Success";
export type AUTH_SUCCESS = typeof AUTH_SUCCESS;

export const AUTH_LOGOUT = "auth/Logout";
export type AUTH_LOGOUT = typeof AUTH_LOGOUT;
