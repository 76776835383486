import {
	BannedUserAction,
	UserAction,
	UserInspectAction,
} from "app-redux/actions/user";
import {
	BANNED_USER_ERROR,
	BANNED_USER_LOADING,
	BANNED_USER_SUCCESS,
	BANNED_USER_UPDATE,
	USER_CLEAR,
	USER_ERROR,
	USER_FORM_INVALID_IMAGES,
	USER_FORM_INVALID_INFOS,
	USER_FORM_LANGUAGE,
	USER_FORM_MESSAGE,
	USER_FORM_MESSAGE_KEYS,
	USER_FORM_STATUS,
	USER_FORM_VALID_INFOS,
	USER_INSPECT_CLEAR,
	USER_INSPECT_ERROR,
	USER_INSPECT_LOADING,
	USER_INSPECT_SELECTED,
	USER_INSPECT_SUCCESS,
	USER_LOADING,
	USER_PENDING_COUNT,
	USER_PROCESS_ORIGINAL_IMAGES_UPDATE,
	USER_PROCESS_SUCCESS,
	USER_REASON_ADD,
	USER_REASON_REMOVE,
	USER_UPDATE,
	USER_VERIFIED_STATUS,
} from "app-redux/types/user";
import { adminMessages } from "config/message";
import {
	AdminMessage,
	AppUser,
	AppUserFull,
	BannedUserState,
	InfoType,
	MessageType,
	SupportedLanguage,
	UserImage,
	UserInspectState,
	UserProcessState,
	UserStatus,
	verified_status,
} from "schema";

const initState: UserProcessState = {
	loading: false,
	error: null,
	user: null,
	pending: 0,
	success: false,
	invalid_images: [],
	invalid_infos: [],
	valid_infos: [],
	messageKeys: { verified: adminMessages["verified"] },
	message: null,
	language: "vi",
	status: "verified",
	original_images: [],
	verified_status: "approved",
};

export function userReducer(
	state = initState,
	action: UserAction,
): UserProcessState {
	switch (action.type) {
		case USER_LOADING:
			return { ...state, loading: true, error: null, success: false };
		case USER_ERROR:
			return { ...state, loading: false, error: action.payload as string };
		case USER_UPDATE:
			return { ...state, loading: false, user: action.payload as AppUser };
		case USER_PENDING_COUNT:
			return { ...state, pending: action.payload as number };
		case USER_PROCESS_SUCCESS:
			return {
				...state,
				loading: false,
				success: true,
				user: null,
				invalid_images: [],
				invalid_infos: [],
				valid_infos: [],
				message: null,
				status: null,
				original_images: [],
			};
		case USER_PROCESS_ORIGINAL_IMAGES_UPDATE:
			return {
				...state,
				original_images: action.payload as UserImage[],
			};
		case USER_FORM_INVALID_IMAGES:
			return { ...state, invalid_images: action.payload as string[] };
		case USER_FORM_INVALID_INFOS:
			return { ...state, invalid_infos: action.payload as InfoType[] };
		case USER_FORM_VALID_INFOS:
			return { ...state, valid_infos: action.payload as InfoType[] };
		case USER_FORM_MESSAGE_KEYS:
			return {
				...state,
				messageKeys: action.payload as { [key in MessageType]?: AdminMessage },
			};
		case USER_FORM_MESSAGE:
			return { ...state, message: action.payload as string };
		case USER_FORM_STATUS:
			return { ...state, status: action.payload as UserStatus };
		case USER_FORM_LANGUAGE:
			return { ...state, language: action.payload as SupportedLanguage };
		case USER_REASON_ADD:
		case USER_REASON_REMOVE:
			return {
				...state,
				user: action.payload as AppUser,
			};
		case USER_VERIFIED_STATUS:
			return {
				...state,
				verified_status: action.payload as verified_status
			};
		case USER_CLEAR:
			return initState;
		default:
			return state;
	}
}

const bannedUserInitState: BannedUserState = {
	loading: false,
	error: null,
	user: null,
};

export function bannedUserReduccer(
	state = bannedUserInitState,
	action: BannedUserAction,
) {
	switch (action.type) {
		case BANNED_USER_LOADING:
			return {
				...state,
				loading: true,
				error: null,
			};
		case BANNED_USER_SUCCESS:
			return {
				...state,
				loading: false,
				user: action.payload as AppUser,
			};
		case BANNED_USER_UPDATE:
			return {
				...state,
				loading: false,
				error: null,
				user: action.payload as AppUser,
			};
		case BANNED_USER_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload as string,
			};
		default:
			return { ...state };
	}
}

const userInspectInitState: UserInspectState = {
	loading: false,
	user: null,
	error: null,
	selected: "",
};

export function userInspectReducer(
	state = userInspectInitState,
	action: UserInspectAction,
): UserInspectState {
	switch (action.type) {
		case USER_INSPECT_LOADING:
			return {
				...state,
				loading: true,
				error: null,
			};
		case USER_INSPECT_SELECTED:
			return {
				...state,
				selected: action.payload as string,
				loading: false,
			};
		case USER_INSPECT_SUCCESS:
			return {
				...state,
				user: action.payload as AppUserFull,
				loading: false,
			};
		case USER_INSPECT_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload as string,
			};
		case USER_INSPECT_CLEAR:
			return {
				...userInspectInitState,
			};
		default:
			return state;
	}
}
