/* eslint-disable @typescript-eslint/no-redeclare */
export const EMPLOYEE_LIST_UPDATE = "employees/Update";
export type EMPLOYEE_LIST_UPDATE = typeof EMPLOYEE_LIST_UPDATE;

export const EMPLOYEE_LIST_LOADING = "employees/Loading";
export type EMPLOYEE_LIST_LOADING = typeof EMPLOYEE_LIST_LOADING;

export const EMPLOYEE_LIST_ERROR = "employees/Error";
export type EMPLOYEE_LIST_ERROR = typeof EMPLOYEE_LIST_ERROR;

export const EMPLOYEE_LOADING = "employee/Loading";
export type EMPLOYEE_LOADING = typeof EMPLOYEE_LOADING;

export const EMPLOYEE_UPDATE = "employee/Update";
export type EMPLOYEE_UPDATE = typeof EMPLOYEE_UPDATE;

export const EMPLOYEE_ERROR = "employee/Error";
export type EMPLOYEE_ERROR = typeof EMPLOYEE_ERROR;

export const EMPLOYEE_CLEAR = "employee/Clear";
export type EMPLOYEE_CLEAR = typeof EMPLOYEE_CLEAR;
