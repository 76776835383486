import { LAYOUT_EXPAND, LAYOUT_COLLAPSED, LAYOUT_TOGGLE } from './../types/layout';
import { LayoutAction } from "app-redux/actions/layout";
import { LayoutState } from "schema";

const initState: LayoutState = {
	collapsed: true,
	title: ''
}

export function layoutReducer(
	state = initState,
	action: LayoutAction
): LayoutState {
	switch (action.type) {
		case LAYOUT_EXPAND:
			return { ...state, collapsed: false };
		case LAYOUT_COLLAPSED:
			return { ...state, collapsed: true };
		case LAYOUT_TOGGLE:
			return {
				...state,
				collapsed: !state.collapsed
			};
		default:
			return state;
	}
}