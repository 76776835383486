import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { ApplicationState, UserRole } from "schema";

type Props = {
	isPrivate?: boolean;
	isAuth?: boolean;
	roles?: UserRole[];
};

export default function RouteWrapper({
	isPrivate,
	isAuth,
	roles,
	...rest
}: RouteProps & Props) {
	const auth = useSelector((state: ApplicationState) => state.auth);

	const signed = auth.signedIn === true && auth.user !== null;

	/**
	 * Redirect user to SignIn page if he tries to access a private route
	 * without authentication.
	 */
	if (isPrivate === true && !signed) {
		return <Redirect to="/" />;
	}
	/**
	 * Redirect user to Main page if he tries to access a non private route
	 * (SignIn or SignUp) after being authenticated.
	 */
	if (isAuth && signed) {
		return <Redirect to="/dashboard" />;
	}

	if (roles && auth.user.role && roles.indexOf(auth.user.role) === -1) {
		return <Redirect to="/dashboard" />;
	}

	/**
	 * If not included on both previous cases, redirect user to the desired route.
	 */
	return <Route {...rest} />;
}
