import { InfoAction } from "app-redux/actions/info";
import {
	INFO_CLEAR,
	INFO_ERROR,
	INFO_FORM_UPDATE_BIO,
	INFO_FORM_UPDATE_NAME,
	INFO_FORM_UPDATE_OCCUPATION,
	INFO_LOADING,
	INFO_SUCCESS,
	INFO_UPDATE,
	INFO_UPDATE_VALID_BIO,
	INFO_UPDATE_VALID_NAME,
	INFO_UPDATE_VALID_OCCUPATION,
} from "app-redux/types/info";
import { AppUser, InfoProcessState } from "schema";

const initState: InfoProcessState = {
	loading: false,
	error: null,
	success: false,
	users: null,
	invalid_bio: [],
	valid_name: [],
	invalid_name: [],
	invalid_occ: [],
	valid_bios: [],
	valid_occupations: [],
};

export function infoReducer(
	state = initState,
	action: InfoAction,
): InfoProcessState {
	switch (action.type) {
		case INFO_LOADING:
			return { ...state, loading: true, error: null, success: false };
		case INFO_ERROR:
			return { ...state, loading: false, error: action.payload as string };
		case INFO_UPDATE:
			return { ...state, loading: false, users: action.payload as AppUser[] };
		case INFO_SUCCESS:
			return {
				...state,
				loading: false,
				success: true,
				users: [],
				invalid_occ: [],
				invalid_bio: [],
			};
		case INFO_UPDATE_VALID_NAME:
			return { ...state, valid_name: action.payload as string[] };
		case INFO_UPDATE_VALID_BIO:
			return { ...state, valid_bios: action.payload as string[] };
		case INFO_UPDATE_VALID_OCCUPATION:
			return { ...state, valid_occupations: action.payload as string[] };
		case INFO_FORM_UPDATE_NAME:
			return { ...state, invalid_name: action.payload as string[] };
		case INFO_FORM_UPDATE_BIO:
			return { ...state, invalid_bio: action.payload as string[] };
		case INFO_FORM_UPDATE_OCCUPATION:
			return { ...state, invalid_occ: action.payload as string[] };
		case INFO_CLEAR:
			return initState;
		default:
			return state;
	}
}
