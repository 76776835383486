import request from 'api/request';
import { HOST } from 'config/api';
import { ApiShiftResponse, LastPartShiftData, WorkingHour } from 'schema';

const ShiftEndPoint = HOST;

export const fetchWorkingHours = (query: { limit?: number; page?: number }) => {
	return request<ApiShiftResponse<LastPartShiftData>>("GET", ShiftEndPoint + "/working_hours", query);
}

export const checkin = (query: { limit?: number; page?: number }) => {
	return request<WorkingHour>("POST", ShiftEndPoint + `/checkin?limit=${query.limit}&page=${query.page}`,);
}

export const checkout = (checkin_id: string) => {
	return request<WorkingHour>("PUT", ShiftEndPoint + `/checkout/${checkin_id}`);
}