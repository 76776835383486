import {
	LogsAnalyticsAction,
	LogsTrackingAction,
	TrackingPerMinAction,
	VerificationTimeAction,
} from "app-redux/actions/logs";
import {
	LOGS_ANALYTICS_CLEAR,
	LOGS_ANALYTICS_ERROR,
	LOGS_ANALYTICS_FILTER,
	LOGS_ANALYTICS_LOADING,
	LOGS_ANALYTICS_UPDATE,
	LOGS_TRACKING_CLEAR,
	LOGS_TRACKING_ERROR,
	LOGS_TRACKING_LOADING,
	LOGS_TRACKING_QUERY,
	LOGS_TRACKING_UPDATE_DATA,
	LOGS_TRACKING_UPDATE_NOTES,
	LOGS_VERIFICATION_PER_MIN_CLEAR,
	LOGS_VERIFICATION_PER_MIN_ERROR,
	LOGS_VERIFICATION_PER_MIN_LOADING,
	LOGS_VERIFICATION_PER_MIN_UPDATE,
	LOGS_VERIFICATION_TIME_CLEAR,
	LOGS_VERIFICATION_TIME_ERROR,
	LOGS_VERIFICATION_TIME_LOADING,
	LOGS_VERIFICATION_TIME_UPDATE,
} from "app-redux/types/logs";
import {
	AnalyticsChartData,
	AnalyticsTableData,
	ApiListResponse,
	EmployeeAnalyticsState,
	EmployeeTrackingState,
	LogRawItem,
	LogRequestQuery,
	LogWarningItem,
	TrackingPerMinState,
	VerificationTimeState,
	VerificationTrackingItem,
} from "schema";

const initTrackingState: EmployeeTrackingState = {
	loading: false,
	error: null,
	results: [],
	warnings: [],
	query: { page: 1, limit: 30 },
	total: 0,
};

export function employeeTrackingReducer(
	state = initTrackingState,
	action: LogsTrackingAction,
): EmployeeTrackingState {
	switch (action.type) {
		case LOGS_TRACKING_LOADING:
			return { ...state, loading: true, error: null };
		case LOGS_TRACKING_ERROR:
			return { ...state, loading: false, error: action.payload as string };
		case LOGS_TRACKING_QUERY:
			return { ...state, query: action.payload as LogRequestQuery };
		case LOGS_TRACKING_UPDATE_NOTES:
			return { ...state, warnings: action.payload as LogWarningItem[] };
		case LOGS_TRACKING_UPDATE_DATA:
			const payload = action.payload as ApiListResponse<LogRawItem>;
			return {
				...state,
				loading: false,
				results: payload.items,
				total: payload.total,
			};
		case LOGS_TRACKING_CLEAR:
			return initTrackingState;
		default:
			return state;
	}
}

const initAnalyticsState: EmployeeAnalyticsState = {
	loading: false,
	error: null,
	chartData: [],
	tableData: [],
	usernames: [],
};

export function employeeReportReducer(
	state = initAnalyticsState,
	action: LogsAnalyticsAction,
): EmployeeAnalyticsState {
	switch (action.type) {
		case LOGS_ANALYTICS_LOADING:
			return { ...state, loading: true, error: null };
		case LOGS_ANALYTICS_ERROR:
			return { ...state, loading: false, error: action.payload as string };
		case LOGS_ANALYTICS_FILTER:
			return {
				...state,
				loading: false,
				usernames: action.payload as string[],
			};
		case LOGS_ANALYTICS_UPDATE:
			const payload = action.payload as [
				AnalyticsChartData[],
				AnalyticsTableData[],
			];
			return {
				...state,
				loading: false,
				chartData: payload[0],
				tableData: payload[1],
			};
		case LOGS_ANALYTICS_CLEAR:
			return initAnalyticsState;
		default:
			return state;
	}
}

const verificationTimeInitState: VerificationTimeState = {
	loading: false,
	chartData: [],
	error: null,
};

export function verificationTimeReducer(
	state = verificationTimeInitState,
	action: VerificationTimeAction,
): VerificationTimeState {
	switch (action.type) {
		case LOGS_VERIFICATION_TIME_LOADING:
			return {
				...state,
				loading: true,
			};
		case LOGS_VERIFICATION_TIME_UPDATE:
			return {
				...state,
				loading: false,
				chartData: action.payload as AnalyticsChartData[],
			};
		case LOGS_VERIFICATION_TIME_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload as string,
			};
		case LOGS_VERIFICATION_TIME_CLEAR:
			return verificationTimeInitState;
		default:
			return { ...state };
	}
}

const trackingPerMinInitState: TrackingPerMinState = {
	loading: false,
	chartData: [],
	error: null,
};

export function trackingPerMinReducer(
	state = trackingPerMinInitState,
	action: TrackingPerMinAction,
): TrackingPerMinState {
	switch (action.type) {
		case LOGS_VERIFICATION_PER_MIN_LOADING:
			return {
				...state,
				loading: true,
			};
		case LOGS_VERIFICATION_PER_MIN_UPDATE:
			return {
				...state,
				loading: false,
				chartData: action.payload as AnalyticsChartData[],
			};
		case LOGS_VERIFICATION_PER_MIN_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload as string,
			};
		case LOGS_VERIFICATION_PER_MIN_CLEAR:
			return trackingPerMinInitState;
		default:
			return { ...state };
	}
}
