import { InfoModel, VerificationUserAction } from "app-redux/actions/user";
import {
	CLEAR,
	CLEAR_INVALID_INFOS,
	ERROR,
	UPDATE_FACE_PHOTOS,
	UPDATE_NEW_INFOS,
	UPDATE_NEW_INVALID_BIOS,
	UPDATE_NEW_INVALID_NAME,
	UPDATE_NEW_INVALID_OCC,
	UPDATE_PROFILE_PHOTOS,
	UPDATE_USER_PENDING,
	UPDATE_VERIFICATION_PHOTOS,
	VERIFICATION_LOADING,
} from "app-redux/types/verification-user";
import {
	IVerificationPhotoModel,
	IUserVerificationState,
	NewUserInfo,
	IPhotoModel,
	IFacePhotoModel,
} from "schema";

const initState: IUserVerificationState = {
	loading: false,
	error: null,
	profile_photos: [],
	face_photos: [],
	verification_photos: null,
	pending: 0,
	invalid_occupations: [],
	invalid_names: [],
	invalid_bios: [],
	valid_names: [],
	valid_bios: [],
	valid_occupations: [],
	new_infos: [],
};

export function userVerificationReducer(
	state = initState,
	{ payload, type }: VerificationUserAction,
): IUserVerificationState {
	switch (type) {
		case VERIFICATION_LOADING:
			return {
				...state,
				loading: true,
				error: null,
				success: false,
			};
		case UPDATE_VERIFICATION_PHOTOS:
			return {
				...state,
				loading: false,
				verification_photos: payload as IVerificationPhotoModel,
			};
		case UPDATE_FACE_PHOTOS:
			return {
				...state,
				loading: false,
				face_photos: payload as IFacePhotoModel[],
			};
		case UPDATE_PROFILE_PHOTOS:
			return {
				...state,
				loading: false,
				profile_photos: payload as IPhotoModel[],
			};
		case UPDATE_USER_PENDING:
			return {
				...state,
				loading: false,
				pending: payload as number,
			};
		case UPDATE_NEW_INFOS:
			return {
				...state,
				loading: false,
				new_infos: payload as NewUserInfo[],
			};
		case UPDATE_NEW_INVALID_BIOS:
			return {
				...state,
				loading: false,
				invalid_bios: payload["invalid"] as string[],
				valid_bios: payload["valid"] as string[],
			};
		case UPDATE_NEW_INVALID_NAME:
			return {
				...state,
				loading: false,
				invalid_names: payload["invalid"] as string[],
				valid_names: payload["valid"] as string[],
			};
		case UPDATE_NEW_INVALID_OCC:
			return {
				...state,
				loading: false,
				invalid_occupations: payload["invalid"] as string[],
				valid_occupations: payload["valid"] as string[],
			};
		case CLEAR_INVALID_INFOS:
			return {
				...state,
				loading: false,
				invalid_bios: [],
				invalid_names: [],
				invalid_occupations: [],
				new_infos: [],
			};
		case ERROR:
			return {
				...state,
				loading: false,
				error: payload as string,
			};
		case CLEAR:
			return initState;
		default:
			return state;
	}
}
