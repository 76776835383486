import { RcFile } from "antd/lib/upload";
import { capitalize } from "lodash";

export function addOrRemoveItem(
	item: string,
	initArray: string[],
	add: boolean,
): string[] {
	let copiedArray: string[] = [];
	if (add) { //nếu là thêm thì làm bình thuognwf
		copiedArray = [...initArray].filter((c) => c !== item);
		copiedArray.push(item);
	} else {
		copiedArray = [...initArray].filter((c) => c !== item);
	}
	return copiedArray;
}

export function addOrRemoveName(
	user_id: string,
	valid_name: string[],
	invalid_name: string[],
	add: boolean,
) {
	let validArray = [...valid_name];
	let inValidArray = [...invalid_name];
	if (!add) {
		validArray.push(user_id);
		inValidArray = inValidArray.filter(i => i !== user_id);
	} else {
		inValidArray.push(user_id);
		validArray = validArray.filter(i => i !== user_id);
	}
	return { validArray, inValidArray }
}

export function addOrRemoveBio(
	user_id: string,
	valid_bios: string[],
	invalid_bios: string[],
	add: boolean,
) {
	let validArray = [...valid_bios];
	let inValidArray = [...invalid_bios];
	if (!add) {
		validArray.push(user_id);
		inValidArray = inValidArray.filter(i => i !== user_id);
	} else {
		inValidArray.push(user_id);
		validArray = validArray.filter(i => i !== user_id);
	}
	return { validArray, inValidArray }
}

export function addOrRemoveOccupation(
	user_id: string,
	valid_occupations: string[],
	invalid_occupations: string[],
	add: boolean,
) {
	let validArray = [...valid_occupations];
	let inValidArray = [...invalid_occupations];
	if (!add) {
		validArray.push(user_id);
		inValidArray = inValidArray.filter(i => i !== user_id);
	} else {
		inValidArray.push(user_id);
		validArray = validArray.filter(i => i !== user_id);
	}
	return { validArray, inValidArray }
}

export const _toBaseUrl = (url: string) => {
	var url_arr: string[] = url.split("/");
	url_arr.pop();
	return url_arr.join("/");
};

export function getDateFromRange(start: any, end: any): string[] {
	for (
		var arr = [], dt = new Date(start);
		dt <= end;
		dt.setDate(dt.getDate() + 1)
	) {
		arr.push(new Date(dt));
	}
	return arr;
}

export function nFormatter(num, digits): string {
	var si = [
		{ value: 1, symbol: "" },
		{ value: 1e3, symbol: "K" },
		{ value: 1e6, symbol: "M" },
		{ value: 1e9, symbol: "G" },
		{ value: 1e12, symbol: "T" },
		{ value: 1e15, symbol: "P" },
		{ value: 1e18, symbol: "E" },
	];
	var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	var i;
	for (i = si.length - 1; i > 0; i--) {
		if (num >= si[i].value) {
			break;
		}
	}
	return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

export function getBase64(file: RcFile) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}

export const readUploadAsText = (file: any): Promise<string> => {
	const reader = new FileReader();
	return new Promise((resolve, reject) => {
		reader.onerror = () => {
			reader.abort();
			reject(new DOMException("Problem parsing input file."));
		};

		reader.onload = () => {
			resolve(reader.result as string);
		};
		reader.readAsDataURL(file);
	});
};

export const snakeCaseToNormal = (str: string): string => {
	if (!str) return "";
	const strArr = str.split("_");
	strArr[0] = capitalize(strArr[0]);
	return strArr.join(" ");
};
