import Loadable from "react-loadable";
import { IRoute } from "schema";
import { Loader } from "uikit";

const EmployeeAnalytics = Loadable({
	loader: () => import("modules/employee/pages/EmployeeAnalytics"),
	loading: Loader,
});

const EmployeeTracking = Loadable({
	loader: () => import("modules/employee/pages/EmployeeTracking"),
	loading: Loader,
});

const VerificationTimeTracking = Loadable({
	loader: () => import("modules/employee/pages/VerificationTimeTracking"),
	loading: Loader,
});

const EmployeeEdit = Loadable({
	loader: () => import("modules/employee/pages/EmployeeEdit"),
	loading: Loader,
});

const EmployeeList = Loadable({
	loader: () => import("modules/employee/pages/EmployeeList"),
	loading: Loader,
});

const Shift = Loadable({
	loader: () => import("modules/exam/pages/Shift"),
	loading: Loader,
})

const IDChecker = Loadable({
	loader: () => import("modules/verification/pages/IdChecker"),
	loading: Loader,
});

const Exam = Loadable({
	loader: () => import("modules/exam/pages/Exam"),
	loading: Loader,
});

const Test = Loadable({
	loader: () => import("modules/exam/pages/Test"),
	loading: Loader,
});

const Result = Loadable({
	loader: () => import("modules/exam/pages/Result"),
	loading: Loader,
});

const ResultDetail = Loadable({
	loader: () => import("modules/exam/pages/ResultDetail"),
	loading: Loader,
});

const Verification = Loadable({
	loader: () => import("modules/verification/pages/Verification"),
	loading: Loader,
});

const ExamProfile = Loadable({
	loader: () => import("modules/exam/pages/CreateProfile"),
	loading: Loader,
});

const FacePhotos = Loadable({
	loader: () => import("modules/verification/pages/face-photos/Verification"),
	loading: Loader,
});

const routes: IRoute[] = [
	{
		component: EmployeeAnalytics,
		path: "/dashboard/employee/analytics",
		roles: ["admin", "super-admin"],
	},
	{
		component: EmployeeTracking,
		path: "/dashboard/employee/tracking",
		roles: ["admin", "super-admin"],
	},
	{
		component: VerificationTimeTracking,
		path: "/dashboard/employee/verification",
		roles: ["admin", "super-admin"],
	},
	{
		component: EmployeeEdit,
		path: "/dashboard/employee/new",
		roles: ["super-admin"],
	},
	{
		component: EmployeeList,
		path: "/dashboard/employee",
		roles: ["super-admin"],
	},
	{
		component: IDChecker,
		path: "/dashboard/idchecker",
		roles: ["super-admin", "admin"],
	},
	{
		component: Shift,
		path: "/dashboard/Shift",
		roles: ["super-admin", "admin", "operator"],
		// exact: true,
	},
	{
		component: Exam,
		path: "/dashboard/exam",
		roles: ["super-admin", "admin"],
		exact: true,
	},
	{
		component: Test,
		path: "/dashboard/exam/test",
		roles: ["operator"],
	},
	{
		component: Result,
		path: "/dashboard/exam/result",
		roles: ["super-admin", "admin", "operator"],
		exact: true,
	},
	{
		component: ResultDetail,
		path: "/dashboard/exam/result/:id",
		roles: ["super-admin", "admin"],
	},
	{
		component: ExamProfile,
		path: "/dashboard/exam/profile",
		roles: ["super-admin", "admin"],
		exact: true,
	},
	{
		component: ExamProfile,
		path: "/dashboard/exam/profile/:id",
		roles: ["super-admin", "admin"],
	},
	{
		component: Verification,
		path: "/dashboard",
		roles: ["super-admin", "admin", "operator"],
	},
	// {
	// 	component: FacePhotos,
	// 	path: "/verification/face-profile-photos",
	// 	roles: ["super-admin", "admin", "operator"],
	// 	exact: true
	// },
];

export default routes;
