import { EmployeeAction, EmployeeListAction } from "app-redux/actions/employee";
import {
	EMPLOYEE_CLEAR,
	EMPLOYEE_ERROR,
	EMPLOYEE_LIST_ERROR,
	EMPLOYEE_LIST_LOADING,
	EMPLOYEE_LIST_UPDATE,
	EMPLOYEE_LOADING,
	EMPLOYEE_UPDATE,
} from "app-redux/types/employee";
import { EmployeeListState, EmployeeState, Operator } from "schema";

const listInitState: EmployeeListState = {
	loading: false,
	error: null,
	employees: [],
};

export function employeeListReducer(
	state = listInitState,
	action: EmployeeListAction
): EmployeeListState {
	switch (action.type) {
		case EMPLOYEE_LIST_LOADING:
			return { ...state, loading: true, error: null };
		case EMPLOYEE_LIST_ERROR:
			return { ...state, loading: false, error: action.payload as string };
		case EMPLOYEE_LIST_UPDATE:
			return {
				...state,
				loading: false,
				error: null,
				employees: action.payload as Operator[],
			};
		default:
			return state;
	}
}

const singleInitState: EmployeeState = {
	loading: false,
	error: null,
	employee: null,
	saved: false,
};

export function employeeReducer(
	state = singleInitState,
	action: EmployeeAction
): EmployeeState {
	switch (action.type) {
		case EMPLOYEE_LOADING:
			return { ...state, loading: true, error: null };
		case EMPLOYEE_ERROR:
			return { ...state, loading: false, error: action.payload as string };
		case EMPLOYEE_UPDATE:
			return {
				...state,
				loading: false,
				error: null,
				saved: true,
				employee: action.payload as Operator,
			};
		case EMPLOYEE_CLEAR:
			return singleInitState;
		default:
			return state;
	}
}
