/* eslint-disable @typescript-eslint/no-redeclare */
export const VERIFICATION_LOADING = "Verification/Loading";
export type VERIFICATION_LOADING = typeof VERIFICATION_LOADING;

export const UPDATE_FACE_PHOTOS = "Verification/facePhotos/update";
export type UPDATE_FACE_PHOTOS = typeof UPDATE_FACE_PHOTOS;

export const UPDATE_PROFILE_PHOTOS = "Verification/profilePhotos/update";
export type UPDATE_PROFILE_PHOTOS = typeof UPDATE_PROFILE_PHOTOS;

export const UPDATE_VERIFICATION_PHOTOS =
	"Verification/verificationPhotos/update";
export type UPDATE_VERIFICATION_PHOTOS = typeof UPDATE_VERIFICATION_PHOTOS;

export const UPDATE_USER_PENDING = "Verification/pending";
export type UPDATE_USER_PENDING = typeof UPDATE_USER_PENDING;

export const UPDATE_NEW_INVALID_BIOS = "Verification/free-text/bios";
export type UPDATE_NEW_INVALID_BIOS = typeof UPDATE_NEW_INVALID_BIOS;

export const UPDATE_NEW_INVALID_OCC = "Verification/free-text/occ";
export type UPDATE_NEW_INVALID_OCC = typeof UPDATE_NEW_INVALID_OCC;

export const UPDATE_NEW_INVALID_NAME = "Verification/free-text/name";
export type UPDATE_NEW_INVALID_NAME = typeof UPDATE_NEW_INVALID_NAME;

export const UPDATE_NEW_INFOS = "Verification/free-text/update";
export type UPDATE_NEW_INFOS = typeof UPDATE_NEW_INFOS;

export const CLEAR_INVALID_INFOS = "Verification/free-text/clear";
export type CLEAR_INVALID_INFOS = typeof CLEAR_INVALID_INFOS;

export const CLEAR = "Verification/clear";
export type CLEAR = typeof CLEAR;

export const ERROR = "Verification/error";
export type ERROR = typeof ERROR;
