import { ShiftApi } from 'api';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { SHIFT_CHECKIN_ID_UPDATE, SHIFT_CURRENT_PART_UDPATE, SHIFT_ERROR, SHIFT_LAST_PARTS_UDPATE, SHIFT_LOADING, SHIFT_QUERY_UPDATE, SHIFT_UPDATE } from '../types/shift';
import { ApiShiftResponse, ApplicationState, LastPartShiftData } from './../../schema/index';
import { autoCheckout } from './info';


export interface shiftAction {
	type:
	| SHIFT_CURRENT_PART_UDPATE
	| SHIFT_LAST_PARTS_UDPATE
	| SHIFT_CHECKIN_ID_UPDATE
	| SHIFT_UPDATE
	| SHIFT_QUERY_UPDATE
	| SHIFT_ERROR
	| SHIFT_LOADING;
	payload?:
	boolean
	| LastPartShiftData[]
	| any
	| string
	| number
	| ApiShiftResponse<LastPartShiftData>;
}

function updateCurrentPart(current: any): shiftAction {
	return { type: SHIFT_CURRENT_PART_UDPATE, payload: current };
}

function updateLastParts(array: LastPartShiftData[]): shiftAction {
	return { type: SHIFT_LAST_PARTS_UDPATE, payload: array };
}

function fetchShiftError(error: string): shiftAction {
	return { type: SHIFT_ERROR, payload: error }
}

function showShiftLoading(): shiftAction {
	return {type: SHIFT_LOADING}
}

function updateCheckinId(checkinId: string): shiftAction {
	return { type: SHIFT_CHECKIN_ID_UPDATE, payload: checkinId };
}

function updateShift(data: ApiShiftResponse<LastPartShiftData>): shiftAction {
	return { type: SHIFT_UPDATE, payload: data };
}

function updateQuery(data: {
	limit?: number;
	page?: number;
}): shiftAction {
	return { type: SHIFT_QUERY_UPDATE, payload: data };
}

export function updateShiftQuery(q) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		const clone = { ...getStore().shift.query };
		if (q) {
			const query = { ...clone, ...q };
			dispatch(updateQuery(query));
			dispatch(getWorkingHours());
		}
	}
}

export function getWorkingHours() {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		dispatch(showShiftLoading());
		try {
			const { query } = getStore().shift;
			const resp = await ShiftApi.fetchWorkingHours(query);
			dispatch(updateShift(resp));

		} catch (err) {
			dispatch(fetchShiftError(err.message))
		}

	}
}

export function checkin() {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		try {
			const { query } = getStore().shift;
			const resp = await ShiftApi.checkin(query);
			localStorage.setItem("currentPart", JSON.stringify(resp.current_part));
			dispatch(updateShift(resp));
			dispatch(autoCheckout());
		} catch (err) {
			dispatch(fetchShiftError(err.message))
		}
	}
}

export function checkout(checkin_id: string) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		try {
			// deleteCheckinTimeFromStorage();
			const resp = await ShiftApi.checkout(checkin_id);
			localStorage.removeItem("currentPart");
			localStorage.removeItem("timeOut");
			localStorage.removeItem("workingHours");
			dispatch(updateCurrentPart(null));
			// dispatch(updateLastParts(resp.last_parts));
		} catch (err) {
			dispatch(fetchShiftError(err.message))
		}
	}
}