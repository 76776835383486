import { SearchUserAction } from "app-redux/actions/searchUser";
import {
	SEARCH_USER_CLEAR,
	SEARCH_USER_ERROR,
	SEARCH_USER_LOADING,
	SEARCH_USER_QUERY,
	SEARCH_USER_UPDATE,
} from "app-redux/types/searchUser";
import {
	ApiListResponse,
	AppUserFull,
	AppUserSearchQuery,
	SearchUserState,
} from "schema";

const initState: SearchUserState = {
	loading: false,
	error: null,
	results: [],
	query: { page: 1, limit: 10 },
	total: 0,
};

export function searchUserReducer(
	state = initState,
	action: SearchUserAction,
): SearchUserState {
	switch (action.type) {
		case SEARCH_USER_LOADING:
			return { ...state, loading: true, error: null };
		case SEARCH_USER_ERROR:
			return { ...state, loading: false, error: action.payload as string };
		case SEARCH_USER_QUERY:
			return { ...state, query: action.payload as AppUserSearchQuery };
		case SEARCH_USER_UPDATE:
			const payload = action.payload as ApiListResponse<AppUserFull>;
			return {
				...state,
				loading: false,
				results: payload.items,
				total: payload.total,
			};
		case SEARCH_USER_CLEAR:
			return initState;
		default:
			return state;
	}
}
