import { ExamResultModel } from './../../schema/index';
import {
	ExamListAction,
	ExamProcessAction,
	ExamProfileAction,
	ExamProfileListAction,
	ExamResultAction,
	ExamResultDetailAction,
	ExamResultListAction,
} from "app-redux/actions/exam";
import {
	EXAM_FINAL_RESULT_DETAIL_UPDATE,
	EXAM_LIST_ERROR,
	EXAM_LIST_LOADING,
	EXAM_LIST_UPDATE,
	EXAM_PROCESS_ANSWER_IMAGES,
	EXAM_PROCESS_ANSWER_INFOS,
	EXAM_PROCESS_ANSWER_VALIDATION,
	EXAM_PROCESS_ERROR,
	EXAM_PROCESS_FINISH,
	EXAM_PROCESS_INIT,
	EXAM_PROCESS_LANGUAGE,
	EXAM_PROCESS_LOADED,
	EXAM_PROCESS_LOADING,
	EXAM_PROCESS_MESSAGE,
	EXAM_PROCESS_MESSAGE_KEYS,
	EXAM_PROCESS_NEXT,
	EXAM_PROCESS_ORIGINAL_IMAGES,
	EXAM_PROCESS_STATUS,
	EXAM_PROCESS_UPDATE,
	EXAM_PROCESS_UPDATE_PROFILE,
	EXAM_PROFILE_CLEAR,
	EXAM_PROFILE_ERROR,
	EXAM_PROFILE_LIST_ERROR,
	EXAM_PROFILE_LIST_LOADING,
	EXAM_PROFILE_LIST_UPDATE,
	EXAM_PROFILE_LOADING,
	EXAM_PROFILE_SUCCESS,
	EXAM_PROFILE_UPDATE,
	EXAM_RESULT_CLEAR,
	EXAM_RESULT_DETAIL_CHART,
	EXAM_RESULT_DETAIL_ERROR,
	EXAM_RESULT_DETAIL_LOADING,
	EXAM_RESULT_DETAIL_UPDATE,
	EXAM_RESULT_ERROR,
	EXAM_RESULT_LIST_ERROR,
	EXAM_RESULT_LIST_LOADING,
	EXAM_RESULT_LIST_SEARCH,
	EXAM_RESULT_LIST_UPDATE,
	EXAM_RESULT_LOADING,
	EXAM_RESULT_QUERY,
	EXAM_RESULT_UPDATE,
} from "app-redux/types/exam";
import { adminMessages } from "config/message";
import {
	AdminMessage,
	Exam,
	ExamListState,
	ExamProcessState,
	ExamProfileInfo,
	ExamProfileListState,
	ExamProfileState,
	ExamResult,
	ExamResultChartItem,
	ExamResultDetailState,
	ExamResultListState,
	ExamResultState,
	FakeProfile,
	FakeProfileImage,
	MessageType,
	SupportedLanguage,
	UserStatus,
} from "schema";

const examProfileInitState: ExamProfileState = {
	loading: false,
	error: null,
	profile: {
		profile_images: [],
	},
	success: false,
};

export function examProfileReducer(
	state = examProfileInitState,
	action: ExamProfileAction,
): ExamProfileState {
	switch (action.type) {
		case EXAM_PROFILE_LOADING:
			return {
				...state,
				loading: true,
			};
		case EXAM_PROFILE_UPDATE:
			return {
				...state,
				loading: false,
				profile: action.payload as FakeProfile,
				// success: true,
			};
		case EXAM_PROFILE_SUCCESS:
			return {
				...state,
				loading: false,
				success: true,
			};
		case EXAM_PROFILE_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload as string,
			};
		case EXAM_PROFILE_CLEAR:
			return {
				...examProfileInitState,
				profile: {
					profile_images: [],
				},
			};
		default:
			return { ...state };
	}
}

const examProfileListInitState = {
	loading: false,
	error: null,
	profiles: [],
};

export function examProfileListReducer(
	state = examProfileListInitState,
	action: ExamProfileListAction,
): ExamProfileListState {
	switch (action.type) {
		case EXAM_PROFILE_LIST_LOADING:
			return {
				...state,
				loading: true,
			};
		case EXAM_PROFILE_LIST_UPDATE:
			return {
				...state,
				loading: false,
				profiles: action.payload as FakeProfile[],
			};
		case EXAM_PROFILE_LIST_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload as string,
			};
		default:
			return { ...state };
	}
}

const examListInitState = {
	loading: false,
	error: null,
	exams: [],
};

export function examListReducer(
	state = examListInitState,
	action: ExamListAction,
): ExamListState {
	switch (action.type) {
		case EXAM_LIST_LOADING:
			return {
				...state,
				loading: true,
			};
		case EXAM_LIST_UPDATE:
			return {
				...state,
				loading: false,
				exams: action.payload as any,
			};
		case EXAM_LIST_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload as string,
			};
		default:
			return { ...state };
	}
}

const examProcessInitState: ExamProcessState = {
	loading: false,
	error: null,
	currentIdx: 0,
	profiles: [],
	processing: false,
	exam: null,
	anwser_images: [],
	anwser_infos: [],
	verification_photo: false,
	message: null,
	messageKeys: { verified: adminMessages["verified"] },
	language: "vi",
	status: "verified",
	original_images: [],
};

export function examProcessReducer(
	state = examProcessInitState,
	action: ExamProcessAction,
): ExamProcessState {
	switch (action.type) {
		case EXAM_PROCESS_INIT:
			const payload = action.payload as {
				exam: Exam;
				profiles: FakeProfile[];
			};
			return {
				...state,
				error: null,
				processing: true,
				exam: payload.exam,
				profiles: payload.profiles,
				anwser_images: [],
				anwser_infos: [],
				verification_photo: false,
				loading: false,
			};
		case EXAM_PROCESS_LOADING:
			return {
				...state,
				loading: true,
			};
		case EXAM_PROCESS_UPDATE_PROFILE:
			return {
				...state,
				profiles: action.payload as FakeProfile[],
			};
		case EXAM_PROCESS_LOADED:
			return {
				...state,
				loading: false,
			};
		case EXAM_PROCESS_NEXT:
			return {
				...state,
				error: null,
				anwser_images: [],
				anwser_infos: [],
				verification_photo: true,
				currentIdx: state.currentIdx + 1,
			};
		case EXAM_PROCESS_ERROR:
			return {
				...state,
				error: action.payload as string,
			};
		case EXAM_PROCESS_ANSWER_INFOS:
			return {
				...state,
				anwser_infos: action.payload as ExamProfileInfo[],
			};
		case EXAM_PROCESS_ANSWER_IMAGES:
			return {
				...state,
				anwser_images: action.payload as ExamProfileInfo[],
			};
		case EXAM_PROCESS_ANSWER_VALIDATION:
			return {
				...state,
				verification_photo: action.payload as boolean,
			};
		case EXAM_PROCESS_UPDATE:
			return {
				...state,
				loading: false,
				processing: false,
			};
		case EXAM_PROCESS_LANGUAGE:
			return {
				...state,
				language: action.payload as SupportedLanguage,
			};
		case EXAM_PROCESS_ORIGINAL_IMAGES:
			return {
				...state,
				original_images: action.payload as FakeProfileImage[],
			};
		case EXAM_PROCESS_STATUS:
			return {
				...state,
				status: action.payload as UserStatus,
			};
		case EXAM_PROCESS_MESSAGE:
			return {
				...state,
				message: action.payload as string,
			};
		case EXAM_PROCESS_MESSAGE_KEYS:
			return {
				...state,
				messageKeys: action.payload as { [key in MessageType]?: AdminMessage },
			};
		case EXAM_PROCESS_FINISH:
			return { ...examProcessInitState };
		default:
			return { ...state };
	}
}

const examResultInitState = {
	loading: false,
	error: null,
	result: null,
	resultDetails: [],
};

export function examResultReducer(
	state = examResultInitState,
	action: ExamResultAction,
): ExamResultState {
	switch (action.type) {
		case EXAM_RESULT_LOADING:
			return {
				...state,
				loading: true,
			};
		case EXAM_RESULT_UPDATE:
			return {
				...state,
				loading: false,
				result: action.payload as ExamResult,
			};
		case EXAM_RESULT_ERROR:
			return { ...state, loading: false, error: action.payload as string };
		case EXAM_RESULT_CLEAR:
			return {
				...examResultInitState,
			};
		default:
			return { ...state };
	}
}

const examResultListInitState: ExamResultListState = {
	loading: false,
	error: null,
	results: [],
	searchResults: [],
	query: {
		page: 0,
		limit: 30,
		total: 0,
	},
};

export function examResultListReducer(
	state = examResultListInitState,
	action: ExamResultListAction,
): ExamResultListState {
	switch (action.type) {
		case EXAM_RESULT_LIST_LOADING:
			return {
				...state,
				loading: true,
			};
		case EXAM_RESULT_LIST_UPDATE:
			return {
				...state,
				loading: false,
				results: action.payload as ExamResult[],
			};
		case EXAM_RESULT_QUERY:
			return {
				...state,
				query: action.payload as {
					page?: number;
					limit?: number;
					total?: number;
				},
			};
		case EXAM_RESULT_LIST_SEARCH:
			return {
				...state,
				searchResults: action.payload as ExamResult[],
			};
		case EXAM_RESULT_LIST_ERROR:
			return { ...state, loading: false, error: action.payload as string };
		default:
			return { ...state };
	}
}

const examResultDetailInitState: ExamResultDetailState = {
	loading: false,
	error: null,
	result: null,
	chartData: [],
	resultDetails: [],
};

export function examResultDetailReducer(
	state = examResultDetailInitState,
	action: ExamResultDetailAction,
): ExamResultDetailState {
	switch (action.type) {
		case EXAM_RESULT_DETAIL_LOADING:
			return {
				...state,
				loading: true,
			};
		case EXAM_RESULT_DETAIL_UPDATE:
			return {
				...state,
				loading: false,
				result: action.payload as ExamResult,
			};
		case EXAM_FINAL_RESULT_DETAIL_UPDATE:
			return {
				...state,
				loading: false,
				resultDetails: action.payload as ExamResultModel[],
			};
		case EXAM_RESULT_DETAIL_CHART:
			return {
				...state,
				chartData: action.payload as ExamResultChartItem[][],
			};
		case EXAM_RESULT_DETAIL_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload as string,
			};
		default:
			return { ...state };
	}
}
