import request from "api/request";
import { HOST_LOGIC } from "config/api";
import {
	IFacePhotoModel,
	IPhotoModel,
	IRequestSubmitFacePhoto,
	IVerificationPhotoModel,
	NewUserInfo
} from "schema";

const UserEndPoint = HOST_LOGIC + "/verified";

export const getPendingUsers = () => {
	return request<any>("GET", UserEndPoint + "/pending");
};
export const getVerificationPhotos = () => {
	return request<IVerificationPhotoModel>("GET", UserEndPoint + "/users");
};
export const getFacePhotos = () => {
	return request<IFacePhotoModel[]>("GET", UserEndPoint + "/face");
};
export const getProfilePhotos = () => {
	return request<IPhotoModel[]>("GET", UserEndPoint + "/profile");
};

export const updateUserStatus = (
	user_id: string,
	status: string,
	verification_filter: boolean = false,
	verification_key?: string,
	key?: string,
	banned_key?: string,
) => {
	return request<any>("PUT", UserEndPoint + "/verified-user", {
		user_id,
		status,
		verification_filter,
		verification_key,
		key,
		banned_key,
	});
};

export const updateProfilePhotos = (data: string[]) => {
	return request<any>("PUT", UserEndPoint + "/verified-profile", data);
};

export const updateFacePhotos = (data: IRequestSubmitFacePhoto[]) => {
	return request<any>("PUT", UserEndPoint + "/verified-face", data);
};

export const removePhoto = (user_id: string, key: string, position: number) => {
	return request<any>("DELETE", UserEndPoint + "/verified-image", {
		user_id,
		key,
		position,
	});
};

export const removeVerificationPhoto = (
	user_id: string,
	verification_key: string,
	key: string,
) => {
	console.log("data", verification_key, key);
	return request<any>(
		"PATCH",
		HOST_LOGIC + "/operator/delete-validate-image/" + user_id,
		{ verification_key, key },
	);
};

export const banUnderAge = (user_id: string) => {
	return request<any>("PUT", HOST_LOGIC + "/operator/banned_user", {
		user: {
			user_id,
			valid_id_status: "initial",
			status: "banned_underage",
		},
	});
};

export const getUserInfos = () => {
	return request<NewUserInfo[]>("GET", UserEndPoint + "/user-infos");
};

export const sendInvalidInfos = (invalid_infos: {
	// all_users: string[];
	invalid_occupations: string[];
	invalid_bios: string[];
	invalid_names: string[];
	valid_occupations?: string[];
	valid_names?: string[];
	valid_bios?: string[];
	admin_sms?: string;
	// language: string;
}) => {
	return request<any>("PUT", UserEndPoint + "/user-infos", { invalid_infos });
};
