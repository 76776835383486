import { logout } from "app-redux/actions/auth";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

export default function Logout() {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(logout());
	}, []);
	return <div></div>;
}
