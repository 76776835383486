import { AuthApi } from "api";
import { KEY_ACCESS_TOKEN } from "config/constants";
import Cookies from "js-cookie";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ApplicationState, Operator } from "schema";
import {
	AUTH_ERROR,
	AUTH_LOADING,
	AUTH_LOGOUT,
	AUTH_SUCCESS,
} from "../types/auth";

export interface AuthAction {
	type: AUTH_LOADING | AUTH_ERROR | AUTH_SUCCESS | AUTH_LOGOUT;
	payload?: Operator | boolean | string;
}

function signInProcessing(): AuthAction {
	return { type: AUTH_LOADING };
}

function signInError(message: string): AuthAction {
	return { type: AUTH_ERROR, payload: message };
}

function signInSuccess(user: Operator): AuthAction {
	return { type: AUTH_SUCCESS, payload: user };
}

export function signOut(): AuthAction {
	return { type: AUTH_LOGOUT };
}

/**
 *
 * @param {string} email
 * @param {string} password
 * @returns {Promise<void>}
 */

export function login(email: string, password: string) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		dispatch(signInProcessing());
		try {
			const token = Cookies.get(KEY_ACCESS_TOKEN);
			if (token == undefined || token == "") {
				const credential = await AuthApi.login(email, password);
				const newToken = Cookies.set(KEY_ACCESS_TOKEN, credential.token, {
					expires: 1,
				});
			}
			const user = await AuthApi.getCurrent();
			dispatch(signInSuccess(user));
		} catch (err) {
			dispatch(signInError(err.message));
		}
	};
}

/**
 * Check user base on access token
 * @returns {void}
 */

export function checkUser() {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		dispatch(signInProcessing());
		try {
			const token = Cookies.get(KEY_ACCESS_TOKEN);
			if (token == undefined || token == "") {
				dispatch(signInError(""));
				return;
			}
			const user = await AuthApi.getCurrent();
			dispatch(signInSuccess(user));
		} catch (err) {
			Cookies.remove(KEY_ACCESS_TOKEN);
			dispatch(signInError(err.message));
		}
	};
}

export function logout() {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		Cookies.remove(KEY_ACCESS_TOKEN);
		dispatch(signOut());
	};
}
