/* eslint-disable react-hooks/exhaustive-deps */
import { MenuOutlined } from "@ant-design/icons";
import { Button, Layout } from "antd";
import { checkUser } from "app-redux/actions/auth";
import { toglleLayout } from "app-redux/actions/layout";
import { checkin, checkout } from "app-redux/actions/shift";
import { map } from "lodash";
import { Login, Logout } from "modules/auth";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Switch } from "react-router-dom";
import { ApplicationState } from "schema";
import { AppHeader } from "uikit";
import WorkingHours from "uikit/components/WorkingHours";
import "./AppRouter.css";
import Route from "./Route";
import routes from "./routes";

const _renderRoute = (): JSX.Element[] => {
	return map(routes, ({ component, ...rest }, idx) => {
		return (
			<Route key={idx} component={component} {...rest} />
		)
	});
};

export default function AppRouters() {
	const dispatch = useDispatch();
	const { currentPart, loading } = useSelector((state: ApplicationState) => state.shift);
	const isMobile = useMediaQuery({
		query: '(max-width: 500px)'
	})

	useEffect(() => {
		dispatch(checkUser());
	}, []);

	return (
		<Switch>
			<Route path={["/dashboard", "/verification"]} isPrivate>
				<Layout hasSider>
					<AppHeader />
					<Layout className="site-main-wrapper">
						<Layout.Header className="site-header-wrapper">
							<MenuOutlined
								style={{ color: "white", fontSize: 25, cursor: "pointer" }}
								onClick={() => {
									dispatch(toglleLayout())
								}}
								className="site-header-wrapper__icon"
							/>
							<div className="button-shift">
								{(currentPart) && <WorkingHours />}
								{!loading && ((currentPart) ?
									<Button
										type="default"
										loading={loading}
										className="btn"
										size={isMobile ? "small" : "middle"}
										onClick={() => dispatch(checkout(currentPart.checkin_id))}
										style={{ backgroundColor: "red", fontWeight: "bold", color: 'white' }}
									>Check out</Button> :
									<Button
										type="default"
										onClick={() => dispatch(checkin())}
										loading={loading}
										size={isMobile ? "small" : "middle"}
										className="btn"
										style={{ backgroundColor: "green", fontWeight: "bold", color: 'white' }}
									>Check in</Button>)}
							</div>
							{/* {user && <div className="user-welcome">Welcome, {user.name}</div>} */}
						</Layout.Header>
						<Layout.Content className="site-content-wrapper">
							<Switch>{_renderRoute()}</Switch>
						</Layout.Content>
					</Layout>
				</Layout>
			</Route>
			<Route component={Logout} path="/logout" isPrivate />
			<Route component={Login} path="/" exact isAuth />
		</Switch>
	);
}
