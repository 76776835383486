import request from "api/request";
import { HOST } from "config/api";
import {
	Exam,
	ExamApiRequest,
	ExamResult,
	FakeProfile,
	FakeProfileImage,
	FakeProfileImageRequest,
	FakeValidationImageRequest,
	TestExamResult,
} from "schema";
import { _toBaseUrl } from "utils";

const ExamEndpoint = _toBaseUrl(HOST) + "/exam";

export const uploadValidationImage = ({
	profile_id,
	type,
	file,
	fail,
}: FakeValidationImageRequest) => {
	const formData = new FormData();
	formData.append("file", file);
	return request<any>(
		"POST",
		ExamEndpoint +
			`/fake_validation_image?profile_id=${profile_id}&fail=${fail}&type=${type}`,
		formData,
	);
};

export const uploadProfileImage = ({
	position,
	profile_id,
	fail,
	file,
	set_face,
}: FakeProfileImageRequest) => {
	const formData = new FormData();
	formData.append("file", file);
	return request<any>(
		"POST",
		ExamEndpoint +
			`/fake_profile_image?position=${position}&profile_id=${profile_id}&fail=${fail}&set_face=${set_face}`,
		formData,
	);
};

export const createProfile = (data: FakeProfile) =>
	request<FakeProfile>("POST", ExamEndpoint + "/fake_profile", {
		profile: data,
	});

export const getAllProfiles = () =>
	request<FakeProfile[]>("GET", ExamEndpoint + "/fake_profiles");

export const getProfileById = (id?: string) =>
	request<FakeProfile>("GET", ExamEndpoint + "/fake_profile/" + id);

export const getExam = () => request<Exam>("GET", ExamEndpoint);

export const doExam = (data: ExamApiRequest) =>
	request<TestExamResult>("POST", ExamEndpoint, data);

export const getExamResult = (exam_id?: string) =>
	request<ExamResult>("GET", ExamEndpoint + "/result", { exam_id });

export const getDetailedExamResult = (operator: string = "superadmin") =>
	request<any>("GET", ExamEndpoint + "/results");

export const getExamResultList = ({
	page = 0,
	limit = 30,
	operator,
}: {
	page?: number;
	limit?: number;
	operator?: string;
}) =>
	request<{ items?: ExamResult[]; total?: number }>(
		"GET",
		ExamEndpoint + "/results",
		{ page, limit, operator },
	);

export const deleteProfile = (profile_id: string) =>
	request<any>(
		"DELETE",
		ExamEndpoint + `/fake_profile?profile_id=${profile_id}`,
	);

export const updateProfile = (profile_id: string, body: FakeProfile) => {
	return request<any>("PUT", ExamEndpoint + `/fake_profile/${profile_id}`, {
		profile: body,
	});
};

export const updateProfileImage = (
	body: FakeProfileImage & { profile_id?: string },
) => request<any>("PUT", ExamEndpoint + "/fake_profile_image", body);

export const updateProfileValidationImage = (
	body: FakeProfileImage & { profile_id?: string },
) => request<any>("PUT", ExamEndpoint + "/fake_validation_image", body);
