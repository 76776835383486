export const SEARCH_USER_LOADING = "SearchUser/Loading";
export type SEARCH_USER_LOADING = typeof SEARCH_USER_LOADING;

export const SEARCH_USER_ERROR = "SearchUser/Error";
export type SEARCH_USER_ERROR = typeof SEARCH_USER_ERROR;

export const SEARCH_USER_QUERY = "SearchUser/Query";
export type SEARCH_USER_QUERY = typeof SEARCH_USER_QUERY;

export const SEARCH_USER_UPDATE = "SearchUser/Update";
export type SEARCH_USER_UPDATE = typeof SEARCH_USER_UPDATE;

export const SEARCH_USER_CLEAR = "SearchUser/Clear";
export type SEARCH_USER_CLEAR = typeof SEARCH_USER_CLEAR;
