import { shiftAction } from "app-redux/actions/shift";
import { SHIFT_CHECKIN_ID_UPDATE, SHIFT_CURRENT_PART_UDPATE, SHIFT_ERROR, SHIFT_LAST_PARTS_UDPATE, SHIFT_LOADING, SHIFT_QUERY_UPDATE, SHIFT_UPDATE } from "app-redux/types/shift";
import { isEmpty } from "lodash";
import { ApiShiftResponse, LastPartShiftData, ShiftState } from "schema";

const initState: ShiftState = {
	error: null,
	loading: false,
	currentPart: null,
	lastParts: [],
	checkinId: null,
	query: {
		limit: 10,
		page: 1,
	},
	total: 0,
};

export function shiftReducer(state = initState, { type, payload }: shiftAction): ShiftState {
	switch (type) {
		case SHIFT_LOADING:
			return { ...state, loading: true, error: null };
		case SHIFT_ERROR:
			return { ...state, error: payload as string, loading: false };
		case SHIFT_UPDATE:
			const result = payload as ApiShiftResponse<LastPartShiftData>;
			return {
				...state,
				currentPart: isEmpty(result.current_part) ? null : result.current_part,
				lastParts: result.last_parts,
				total: result.total,
				loading: false,
			};
		case SHIFT_QUERY_UPDATE:
			return {
				...state, query: payload as { limit?: number, page?: number }, loading: false,
			}
		case SHIFT_CURRENT_PART_UDPATE:
			return { ...state, currentPart: payload as any, loading: false, };
		case SHIFT_LAST_PARTS_UDPATE:
			return { ...state, lastParts: payload as LastPartShiftData[], loading: false, };
		case SHIFT_CHECKIN_ID_UPDATE:
			return { ...state, checkinId: payload as string, loading: false };
		default:
			return state;
	}
}