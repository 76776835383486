import { Operator } from "schema";
import { HOST } from "config/api";
import request from "../request";

const AuthEndPoint = HOST;

export const login = (username: String, password: String) =>
	request<{ token: String }>("POST", AuthEndPoint + "/login", {
		user: { username, password },
	});

export const getCurrent = () => request<Operator>("GET", AuthEndPoint);
