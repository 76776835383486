export const SHIFT_LOADING = "shift/loading";
export type SHIFT_LOADING = typeof SHIFT_LOADING;

export const SHIFT_ERROR = "shift/error";
export type SHIFT_ERROR = typeof SHIFT_ERROR;

export const SHIFT_UPDATE = "shift/update";
export type SHIFT_UPDATE = typeof SHIFT_UPDATE;

export const SHIFT_QUERY_UPDATE = "shift/query/update";
export type SHIFT_QUERY_UPDATE = typeof SHIFT_QUERY_UPDATE;

export const SHIFT_CURRENT_PART_UDPATE = "shift/currentPart/update";
export type SHIFT_CURRENT_PART_UDPATE = typeof SHIFT_CURRENT_PART_UDPATE;

export const SHIFT_LAST_PARTS_UDPATE = "shift/lastParts/update";
export type SHIFT_LAST_PARTS_UDPATE = typeof SHIFT_LAST_PARTS_UDPATE;

export const SHIFT_CHECKIN_ID_UPDATE = "shift/checkinId/error";
export type SHIFT_CHECKIN_ID_UPDATE = typeof SHIFT_CHECKIN_ID_UPDATE;
