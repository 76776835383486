import { HOST } from "config/api";
import { AppUser } from "schema";
import request from "../request";

const InfoEndPoint = HOST + "/changes";

export const get = () => request<AppUser[]>("GET", InfoEndPoint);

export const process = (invalid_infos: {
	// all_users: string[];
	invalid_occupations: string[];
	invalid_bios: string[];
	invalid_names: string[];
	valid_occupations: string[];
	valid_names: string[];
	valid_bios: string[];
	admin_sms: string;
	// language: string;
}) => request<any>("PUT", InfoEndPoint, { invalid_infos });
