/* eslint-disable @typescript-eslint/no-redeclare */
export const EXAM_PROFILE_LOADING = "ExamProfile/Loading";
export type EXAM_PROFILE_LOADING = typeof EXAM_PROFILE_LOADING;

export const EXAM_PROFILE_UPDATE = "ExamProfile/Update";
export type EXAM_PROFILE_UPDATE = typeof EXAM_PROFILE_UPDATE;

export const EXAM_PROFILE_ERROR = "ExamProfile/Error";
export type EXAM_PROFILE_ERROR = typeof EXAM_PROFILE_ERROR;

export const EXAM_PROFILE_SUCCESS = "ExamProfile/Success";
export type EXAM_PROFILE_SUCCESS = typeof EXAM_PROFILE_SUCCESS;

export const EXAM_PROFILE_CLEAR = "ExamProfile/Clear";
export type EXAM_PROFILE_CLEAR = typeof EXAM_PROFILE_CLEAR;

export const EXAM_PROFILE_IMAGE_UPDATE = "ExamProfileImage/Update";
export type EXAM_PROFILE_IMAGE_UPDATE = typeof EXAM_PROFILE_IMAGE_UPDATE;

export const EXAM_PROFILE_LIST_LOADING = "ExamProfileList/Loading";
export type EXAM_PROFILE_LIST_LOADING = typeof EXAM_PROFILE_LIST_LOADING;

export const EXAM_PROFILE_LIST_UPDATE = "ExamProfileList/Update";
export type EXAM_PROFILE_LIST_UPDATE = typeof EXAM_PROFILE_LIST_UPDATE;

export const EXAM_PROFILE_LIST_ERROR = "ExamProfileList/Error";
export type EXAM_PROFILE_LIST_ERROR = typeof EXAM_PROFILE_LIST_ERROR;

export const EXAM_LIST_LOADING = "ExamList/Loading";
export type EXAM_LIST_LOADING = typeof EXAM_LIST_LOADING;

export const EXAM_LIST_UPDATE = "ExamList/Update";
export type EXAM_LIST_UPDATE = typeof EXAM_LIST_UPDATE;

export const EXAM_LIST_ERROR = "ExamList/Error";
export type EXAM_LIST_ERROR = typeof EXAM_LIST_ERROR;

export const EXAM_PROCESS_INIT = "ExamProcess/Init";
export type EXAM_PROCESS_INIT = typeof EXAM_PROCESS_INIT;

export const EXAM_PROCESS_LOADING = "ExamProcess/Loading";
export type EXAM_PROCESS_LOADING = typeof EXAM_PROCESS_LOADING;

export const EXAM_PROCESS_ANSWER_INFOS = "ExamProcess/AnwserInfo";
export type EXAM_PROCESS_ANSWER_INFOS = typeof EXAM_PROCESS_ANSWER_INFOS;

export const EXAM_PROCESS_ANSWER_IMAGES = "ExamProcess/AnwserImages";
export type EXAM_PROCESS_ANSWER_IMAGES = typeof EXAM_PROCESS_ANSWER_IMAGES;

export const EXAM_PROCESS_ANSWER_VALIDATION = "ExamProcess/AnwserValidation";
export type EXAM_PROCESS_ANSWER_VALIDATION =
	typeof EXAM_PROCESS_ANSWER_VALIDATION;

export const EXAM_PROCESS_NEXT = "ExamProcess/Next";
export type EXAM_PROCESS_NEXT = typeof EXAM_PROCESS_NEXT;

export const EXAM_PROCESS_UPDATE = "ExamProcess/Update";
export type EXAM_PROCESS_UPDATE = typeof EXAM_PROCESS_UPDATE;

export const EXAM_PROCESS_FINISH = "ExamProcess/Finish";
export type EXAM_PROCESS_FINISH = typeof EXAM_PROCESS_FINISH;

export const EXAM_PROCESS_LOADED = "ExamProcess/Loaded";
export type EXAM_PROCESS_LOADED = typeof EXAM_PROCESS_LOADED;

export const EXAM_PROCESS_ERROR = "ExamProcess/Error";
export type EXAM_PROCESS_ERROR = typeof EXAM_PROCESS_ERROR;

export const EXAM_PROCESS_ORIGINAL_IMAGES = "ExamProcess/OriginalImages";
export type EXAM_PROCESS_ORIGINAL_IMAGES = typeof EXAM_PROCESS_ORIGINAL_IMAGES;

export const EXAM_PROCESS_UPDATE_PROFILE = "ExamProcess/UpdateProfile";
export type EXAM_PROCESS_UPDATE_PROFILE = typeof EXAM_PROCESS_UPDATE_PROFILE;

export const EXAM_PROCESS_LANGUAGE = "ExamProcess/Language";
export type EXAM_PROCESS_LANGUAGE = typeof EXAM_PROCESS_LANGUAGE;

export const EXAM_PROCESS_MESSAGE_KEYS = "ExamProcess/MessageKeys";
export type EXAM_PROCESS_MESSAGE_KEYS = typeof EXAM_PROCESS_MESSAGE_KEYS;

export const EXAM_PROCESS_MESSAGE = "ExamProcess/Message";
export type EXAM_PROCESS_MESSAGE = typeof EXAM_PROCESS_MESSAGE;

export const EXAM_PROCESS_STATUS = "ExamProcess/Status";
export type EXAM_PROCESS_STATUS = typeof EXAM_PROCESS_STATUS;

export const EXAM_RESULT_LOADING = "ExamResult/Loading";
export type EXAM_RESULT_LOADING = typeof EXAM_RESULT_LOADING;

export const EXAM_RESULT_UPDATE = "ExamResult/Update";
export type EXAM_RESULT_UPDATE = typeof EXAM_RESULT_UPDATE;

export const EXAM_RESULT_ERROR = "ExamResult/Error";
export type EXAM_RESULT_ERROR = typeof EXAM_RESULT_ERROR;

export const EXAM_RESULT_QUERY = "ExamResult/Query";
export type EXAM_RESULT_QUERY = typeof EXAM_RESULT_QUERY;

export const EXAM_RESULT_CLEAR = "ExamResult/Clear";
export type EXAM_RESULT_CLEAR = typeof EXAM_RESULT_CLEAR;

export const EXAM_RESULT_LIST_LOADING = "ExamResultList/Loading";
export type EXAM_RESULT_LIST_LOADING = typeof EXAM_RESULT_LIST_LOADING;

export const EXAM_RESULT_LIST_UPDATE = "ExamResultList/Update";
export type EXAM_RESULT_LIST_UPDATE = typeof EXAM_RESULT_LIST_UPDATE;

export const EXAM_RESULT_LIST_ERROR = "ExamResultList/Error";
export type EXAM_RESULT_LIST_ERROR = typeof EXAM_RESULT_LIST_ERROR;

export const EXAM_RESULT_LIST_SEARCH = "ExamResultList/Search";
export type EXAM_RESULT_LIST_SEARCH = typeof EXAM_RESULT_LIST_SEARCH;

export const EXAM_RESULT_DETAIL_LOADING = "ExamResultDetail/Loading";
export type EXAM_RESULT_DETAIL_LOADING = typeof EXAM_RESULT_DETAIL_LOADING;

export const EXAM_RESULT_DETAIL_UPDATE = "ExamResultDetail/Update";
export type EXAM_RESULT_DETAIL_UPDATE = typeof EXAM_RESULT_DETAIL_UPDATE;

export const EXAM_RESULT_DETAIL_ERROR = "ExamResultDetail/Error";
export type EXAM_RESULT_DETAIL_ERROR = typeof EXAM_RESULT_DETAIL_ERROR;

export const EXAM_RESULT_DETAIL_CHART = "ExamResultDetail/ChartUpdate";
export type EXAM_RESULT_DETAIL_CHART = typeof EXAM_RESULT_DETAIL_CHART;

export const EXAM_RESULT_DETAIL_CLEAR = "ExamResultDetail/Clear";
export type EXAM_RESULT_DETAIL_CLEAR = typeof EXAM_RESULT_DETAIL_CLEAR;

export const EXAM_FINAL_RESULT_DETAIL_UPDATE = "ExamResultDetail/Clear";
export type EXAM_FINAL_RESULT_DETAIL_UPDATE = typeof EXAM_FINAL_RESULT_DETAIL_UPDATE;

