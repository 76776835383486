/* eslint-disable @typescript-eslint/no-unused-vars */
import { message } from "antd";
import { InfoApi, VerificationUserApi } from "api";
import {
	INFO_CLEAR,
	INFO_ERROR,
	INFO_FORM_UPDATE_BIO,
	INFO_FORM_UPDATE_NAME,
	INFO_FORM_UPDATE_OCCUPATION,
	INFO_LOADING,
	INFO_SUCCESS,
	INFO_UPDATE,
	INFO_UPDATE_VALID_BIO,
	INFO_UPDATE_VALID_NAME,
	INFO_UPDATE_VALID_OCCUPATION,
} from "app-redux/types/info";
import { adminMessages } from "config/message";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ApplicationState, AppUser } from "schema";
import { addOrRemoveBio, addOrRemoveName, addOrRemoveOccupation } from "utils";
import { checkout } from "./shift";
import { loadUser } from "./user";

export interface InfoAction {
	type:
		| INFO_CLEAR
		| INFO_ERROR
		| INFO_FORM_UPDATE_BIO
		| INFO_FORM_UPDATE_OCCUPATION
		| INFO_FORM_UPDATE_NAME
		| INFO_LOADING
		| INFO_UPDATE
		| INFO_UPDATE_VALID_BIO
		| INFO_UPDATE_VALID_OCCUPATION
		| INFO_UPDATE_VALID_NAME
		| INFO_SUCCESS;
	payload?: AppUser[] | string | string[];
}

function infoLoading(): InfoAction {
	return { type: INFO_LOADING };
}

function infoError(message: string): InfoAction {
	return { type: INFO_ERROR, payload: message };
}

function infoUpdate(users: AppUser[]): InfoAction {
	return { type: INFO_UPDATE, payload: users };
}

function infoSuccess(): InfoAction {
	return { type: INFO_CLEAR };
}

export function clearInfo(): InfoAction {
	return { type: INFO_CLEAR };
}

function infoForm_updateInvalidName(array: string[]): InfoAction {
	return { type: INFO_FORM_UPDATE_NAME, payload: array };
}

function infoForm_updateInvalidBio(array: string[]): InfoAction {
	return { type: INFO_FORM_UPDATE_BIO, payload: array };
}

function infoForm_updateInvalidOcc(array: string[]): InfoAction {
	return { type: INFO_FORM_UPDATE_OCCUPATION, payload: array };
}

function infoForm_updateValidName(array: string[]): InfoAction {
	return { type: INFO_UPDATE_VALID_NAME, payload: array };
}

function infoForm_updateValidBio(array: string[]): InfoAction {
	return { type: INFO_UPDATE_VALID_BIO, payload: array };
}

function infoForm_updateValidOccupation(array: string[]): InfoAction {
	return { type: INFO_UPDATE_VALID_OCCUPATION, payload: array };
}

export function addOrRemoveInvalidName(userId: string, add: boolean) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		const { valid_name, invalid_name } = getStore().infoProcess;
		let { validArray, inValidArray } = addOrRemoveName(
			userId,
			valid_name,
			invalid_name,
			add,
		);
		dispatch(infoForm_updateInvalidName(inValidArray));
		dispatch(infoForm_updateValidName(validArray));
	};
}

export function addOrRemoveInvalidBio(userId: string, add: boolean) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		const { valid_bios, invalid_bio } = getStore().infoProcess;
		let { validArray, inValidArray } = addOrRemoveBio(
			userId,
			valid_bios,
			invalid_bio,
			add,
		);
		dispatch(infoForm_updateInvalidBio(inValidArray));
		dispatch(infoForm_updateValidBio(validArray));
	};
}

export function addOrRemoveInvalidOccupation(userId: string, add: boolean) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		const { valid_occupations, invalid_occ } = getStore().infoProcess;
		let { validArray, inValidArray } = addOrRemoveOccupation(
			userId,
			valid_occupations,
			invalid_occ,
			add,
		);
		dispatch(infoForm_updateInvalidOcc(inValidArray));
		dispatch(infoForm_updateValidOccupation(validArray));
	};
}

export function getUserPending() {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		const currentPart = getStore().shift.currentPart;
		if (!currentPart) {
			message.warn("You should check in first");
		}
		dispatch(loadUser());
		dispatch(loadInfos());
	};
}

let timer;

export function autoCheckout() {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		const currentPart = getStore().shift.currentPart;
		if (currentPart) {
			const timeout = localStorage.getItem("timeOut");
			clearTimeout(timer);
			timer = setTimeout(() => {
				dispatch(checkout(currentPart.checkin_id));
			}, 900000);
			if (timeout === null) {
				const isTimeOut = true;
				localStorage.setItem("timeOut", JSON.stringify(isTimeOut));
			} else {
				clearTimeout(timer);
				timer = setTimeout(() => {
					dispatch(checkout(currentPart.checkin_id));
				}, 900000);
			}
		} else {
		}
	};
}

export function loadInfos() {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		dispatch(clearInfo());
		dispatch(infoLoading());
		try {
			const infos = await InfoApi.get();
			const new_user_infos = await VerificationUserApi.getUserInfos();
			const {
				valid_name,
				valid_bios,
				valid_occupations,
				invalid_name,
				invalid_bio,
				invalid_occ,
			} = getStore().infoProcess;
			infos?.forEach((info) => {
				if (info.bio) {
					let { validArray } = addOrRemoveBio(
						info.user_id,
						valid_bios,
						invalid_bio,
						false,
					);
					dispatch(infoForm_updateValidBio(validArray));
				}
				if (info.occupation) {
					let { validArray } = addOrRemoveOccupation(
						info.user_id,
						valid_occupations,
						invalid_occ,
						false,
					);
					dispatch(infoForm_updateValidOccupation(validArray));
				}

				if (info.name) {
					let { validArray } = addOrRemoveName(
						info.user_id,
						valid_name,
						invalid_name,
						false,
					);
					dispatch(infoForm_updateValidName(validArray));
				}
			});
			if (infos?.length !== 0) dispatch(infoUpdate(infos));
			dispatch(autoCheckout());
		} catch (err) {
			dispatch(infoError(err.message));
		}
	};
}

export function processInfos() {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		dispatch(infoLoading());
		try {
			const {
				invalid_name,
				invalid_bio: invalid_bios,
				invalid_occ: invalid_occupations,
				valid_name,
				valid_occupations,
				valid_bios,
			} = getStore().infoProcess;
			let sms: string[] = [];
			let message: string = null;
			if (
				invalid_occupations.length === 0 &&
				invalid_bios.length === 0 &&
				invalid_name.length === 0
			) {
				message = "";
			} else {
				if (invalid_bios.length !== 0) {
					sms.push(adminMessages["invalid-bio"].vi);
				}
				if (invalid_occupations.length !== 0) {
					sms.push(adminMessages["invalid-occupation"].vi);
				}
				if (invalid_name.length !== 0) {
					sms.push(adminMessages["invalid-name"].vi);
				}
			}
			message = sms.join("\n");
			const _ = await InfoApi.process({
				invalid_bios,
				invalid_names: invalid_name,
				invalid_occupations,
				valid_occupations,
				valid_bios,
				valid_names: valid_name,
				admin_sms: message,
			});
			dispatch(infoSuccess());
		} catch (err) {
			dispatch(infoError(err.message));
		}
	};
}
