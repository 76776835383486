import { HOST, HOST_LOGIC } from "config/api";
import {
	ApiListResponse,
	AppUser,
	AppUserApiData,
	AppUserFull,
	AppUserSearchQuery,
	UserStatus,
} from "schema";
import request from "../request";

const UserEndPoint = HOST + "/user";


export const get = () => request<AppUser>("GET", UserEndPoint);

export const getInvalidUsers = () => request<AppUser>("GET", HOST_LOGIC + "/verified/users?page_number=0&page_chunk_size=10");

export const getPendingCount = () =>
	request<number>("GET", UserEndPoint + "/pending");

export const process = (user: AppUserApiData) =>
	request<AppUser>("PUT", UserEndPoint, { user });

export const deleteValidateImage = (user_id: string) =>
	request<any>("PATCH", HOST + "/delete-validate-image/" + user_id);

export const search = (query: AppUserSearchQuery) =>
	request<ApiListResponse<AppUserFull>>("GET", UserEndPoint + "s", query);

export const update = (userId: string, status: UserStatus) =>
	request<any>("PUT", UserEndPoint + "s/" + userId, { user: { status } });

export const getBanned = () => request<AppUser>("GET", `${HOST}/banned_user`);

export const updateBannedStatus = (slug: string, valid_id_status: string, status: string) =>
	request<any>("PUT", `${HOST}/banned_user`, {
		user: { user_id: slug, valid_id_status, status },
	});


export const getById = (user_id: string) => request<AppUser>("GET", UserEndPoint + 's/' + user_id);
