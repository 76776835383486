import { Button, Form, Input } from "antd";
import { login } from "app-redux/actions/auth";
import React from "react";
import { useDispatch } from "react-redux";
import "./LoginForm.css";

const layout = {
	labelCol: { span: 24 },
	wrapperCol: { span: 24 },
};
export default function LoginForm() {
	const dispatch = useDispatch();

	const onFinish = (values) => {
		console.log("Success:", values);
		const { email, password } = values;
		dispatch(login(email, password));
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
		// message.error(errorInfo);
	};
	return (
		<div className="login-form">
			<h1>Đăng nhập</h1>
			<Form
				{...layout}
				name="basic"
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				// initialValues={{
				// 	email: "thiai.fika@gmail.com",
				// 	password: "1111111",
				// }}
			>
				<Form.Item
					label="Username"
					name="email"
					rules={[{ required: true, message: "Vui lòng nhập username!" }]}
				>
					<Input placeholder="Nhập tài khoản đăng nhập" />
				</Form.Item>

				<Form.Item
					label="Mật khẩu"
					name="password"
					rules={[{ required: true, message: "Vui lòng nhập mật khẩu!" }]}
				>
					<Input.Password placeholder="Nhập mật khẩu" />
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						className="login-button-login"
					>
						Đăng nhập
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
}
