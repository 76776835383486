/* eslint-disable @typescript-eslint/no-redeclare */
export const USER_LOADING = "User/Loading";
export type USER_LOADING = typeof USER_LOADING;

export const USER_ERROR = "User/Error";
export type USER_ERROR = typeof USER_ERROR;

export const USER_UPDATE = "User/Update";
export type USER_UPDATE = typeof USER_UPDATE;

export const USER_PENDING_COUNT = "User/PendingCount";
export type USER_PENDING_COUNT = typeof USER_PENDING_COUNT;

export const USER_PROCESS_SUCCESS = "User/Success";
export type USER_PROCESS_SUCCESS = typeof USER_PROCESS_SUCCESS;

export const USER_PROCESS_ORIGINAL_IMAGES_UPDATE =
	"UserProcessOriginalImages/Update";
export type USER_PROCESS_ORIGINAL_IMAGES_UPDATE =
	typeof USER_PROCESS_ORIGINAL_IMAGES_UPDATE;

export const USER_FORM_STATUS = "User/Form/Status";
export type USER_FORM_STATUS = typeof USER_FORM_STATUS;

export const USER_FORM_INVALID_IMAGES = "User/Form/InvalidImages";
export type USER_FORM_INVALID_IMAGES = typeof USER_FORM_INVALID_IMAGES;

export const USER_FORM_INVALID_INFOS = "User/Form/InvalidInfos";
export type USER_FORM_INVALID_INFOS = typeof USER_FORM_INVALID_INFOS;

export const USER_FORM_VALID_INFOS = "User/Form/ValidInfos";
export type USER_FORM_VALID_INFOS = typeof USER_FORM_VALID_INFOS;

export const USER_FORM_MESSAGE_KEYS = "User/Form/MessageKeys";
export type USER_FORM_MESSAGE_KEYS = typeof USER_FORM_MESSAGE_KEYS;

export const USER_FORM_MESSAGE = "User/Form/Message";
export type USER_FORM_MESSAGE = typeof USER_FORM_MESSAGE;

export const USER_FORM_LANGUAGE = "User/Form/Language";
export type USER_FORM_LANGUAGE = typeof USER_FORM_LANGUAGE;

export const USER_CLEAR = "User/Clear";
export type USER_CLEAR = typeof USER_CLEAR;

export const USER_REASON_ADD = "User/ReasonAdd";
export type USER_REASON_ADD = typeof USER_REASON_ADD;

export const USER_REASON_REMOVE = "User/ReasonRemove";
export type USER_REASON_REMOVE = typeof USER_REASON_REMOVE;

export const USER_VERIFIED_STATUS = "User/VerifiedStatus";
export type USER_VERIFIED_STATUS = typeof USER_VERIFIED_STATUS;

export const BANNED_USER_LOADING = "Banned/Loading";
export type BANNED_USER_LOADING = typeof BANNED_USER_LOADING;

export const BANNED_USER_SUCCESS = "Banned/Success";
export type BANNED_USER_SUCCESS = typeof BANNED_USER_SUCCESS;

export const BANNED_USER_ERROR = "Banned/Error";
export type BANNED_USER_ERROR = typeof BANNED_USER_ERROR;

export const BANNED_USER_UPDATE = "Banned/Update";
export type BANNED_USER_UPDATE = typeof BANNED_USER_UPDATE;

export const USER_INSPECT_LOADING = "UserInspect/Loading";
export type USER_INSPECT_LOADING = typeof USER_INSPECT_LOADING;

export const USER_INSPECT_SELECTED = "UserInspect/Selected";
export type USER_INSPECT_SELECTED = typeof USER_INSPECT_SELECTED;

export const USER_INSPECT_SUCCESS = "UserInspect/Success";
export type USER_INSPECT_SUCCESS = typeof USER_INSPECT_SUCCESS;

export const USER_INSPECT_ERROR = "UserInspect/Error";
export type USER_INSPECT_ERROR = typeof USER_INSPECT_ERROR;

export const USER_INSPECT_CLEAR = "UserInspect/Clear";
export type USER_INSPECT_CLEAR = typeof USER_INSPECT_CLEAR;
