import {
	LAYOUT_EXPAND,
	LAYOUT_TOGGLE,
	LAYOUT_COLLAPSED,
} from "./../types/layout";

export interface LayoutAction {
	type: LAYOUT_EXPAND | LAYOUT_COLLAPSED | LAYOUT_TOGGLE;
	payload?: boolean | string;
}

export function expandLayout(): LayoutAction {
	return { type: LAYOUT_EXPAND };
}

export function collapsedLayout(): LayoutAction {
	return { type: LAYOUT_COLLAPSED };
}

export function toglleLayout(): LayoutAction {
	return { type: LAYOUT_TOGGLE };
}
