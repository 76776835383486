import { message } from "antd";
import { UserApi } from "api";
import {
	BANNED_USER_ERROR,
	BANNED_USER_LOADING,
	BANNED_USER_SUCCESS,
	BANNED_USER_UPDATE,
	USER_CLEAR,
	USER_ERROR,
	USER_FORM_INVALID_IMAGES,
	USER_FORM_INVALID_INFOS,
	USER_FORM_LANGUAGE,
	USER_FORM_MESSAGE,
	USER_FORM_MESSAGE_KEYS,
	USER_FORM_STATUS,
	USER_FORM_VALID_INFOS,
	USER_INSPECT_CLEAR,
	USER_INSPECT_ERROR,
	USER_INSPECT_LOADING,
	USER_INSPECT_SELECTED,
	USER_INSPECT_SUCCESS,
	USER_LOADING,
	USER_PENDING_COUNT,
	USER_PROCESS_ORIGINAL_IMAGES_UPDATE,
	USER_PROCESS_SUCCESS,
	USER_REASON_ADD,
	USER_REASON_REMOVE,
	USER_UPDATE,
	USER_VERIFIED_STATUS,
} from "app-redux/types/user";
import {
	CLEAR,
	CLEAR_INVALID_INFOS,
	ERROR,
	UPDATE_FACE_PHOTOS,
	UPDATE_NEW_INFOS,
	UPDATE_NEW_INVALID_BIOS,
	UPDATE_NEW_INVALID_NAME,
	UPDATE_NEW_INVALID_OCC,
	UPDATE_PROFILE_PHOTOS,
	UPDATE_USER_PENDING,
	UPDATE_VERIFICATION_PHOTOS,
	VERIFICATION_LOADING,
} from "app-redux/types/verification-user";
import { adminMessages, messageKeyValue } from "config/message";
import { filter, findIndex, forEach, indexOf, isEmpty, map } from "lodash";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
	AdminMessage,
	ApplicationState,
	AppUser,
	AppUserFull,
	InfoType,
	IPhotoModel,
	IVerificationPhotoModel,
	MessageType,
	NewUserInfo,
	SupportedLanguage,
	UserImage,
	UserStatus,
	valid_id_status,
	verified_status,
} from "schema";

export interface UserAction {
	type:
		| USER_CLEAR
		| USER_ERROR
		| USER_FORM_INVALID_IMAGES
		| USER_FORM_INVALID_INFOS
		| USER_FORM_VALID_INFOS
		| USER_FORM_MESSAGE_KEYS
		| USER_FORM_MESSAGE
		| USER_FORM_STATUS
		| USER_FORM_LANGUAGE
		| USER_LOADING
		| USER_UPDATE
		| USER_PROCESS_SUCCESS
		| USER_PENDING_COUNT
		| USER_REASON_ADD
		| USER_REASON_REMOVE
		| USER_PROCESS_ORIGINAL_IMAGES_UPDATE
		| USER_VERIFIED_STATUS;
	payload?:
		| AppUser
		| string
		| string[]
		| UserStatus
		| number
		| verified_status
		| InfoType[]
		| SupportedLanguage
		| { [key in MessageType]?: AdminMessage }
		| UserImage[];
}

export interface InfoModel {
	valid: string[];
	invalid: string[];
}
export interface VerificationUserAction {
	type:
		| UPDATE_FACE_PHOTOS
		| UPDATE_PROFILE_PHOTOS
		| UPDATE_VERIFICATION_PHOTOS
		| ERROR
		| CLEAR
		| VERIFICATION_LOADING
		| UPDATE_USER_PENDING
		| UPDATE_NEW_INVALID_BIOS
		| UPDATE_NEW_INVALID_OCC
		| UPDATE_NEW_INVALID_NAME
		| UPDATE_NEW_INFOS
		| CLEAR_INVALID_INFOS;
	payload?:
		| string[]
		| IPhotoModel
		| IPhotoModel[]
		| NewUserInfo
		| NewUserInfo[]
		| InfoModel
		| string
		| IVerificationPhotoModel[]
		| number
		| IVerificationPhotoModel;
}

export interface BannedUserAction {
	type:
		| BANNED_USER_ERROR
		| BANNED_USER_LOADING
		| BANNED_USER_SUCCESS
		| BANNED_USER_UPDATE;
	payload?: string | AppUser | any;
}

export interface UserInspectAction {
	type:
		| USER_INSPECT_LOADING
		| USER_INSPECT_SUCCESS
		| USER_INSPECT_SELECTED
		| USER_INSPECT_ERROR
		| USER_INSPECT_CLEAR;
	payload?: AppUserFull | string;
}

function userLoading(): UserAction {
	return { type: USER_LOADING };
}

function userError(message: string): UserAction {
	return { type: USER_ERROR, payload: message };
}

function userUpdate(user: AppUser): UserAction {
	return { type: USER_UPDATE, payload: user };
}

function userOriginalImagesUpdate(data: UserImage[]): UserAction {
	return { type: USER_PROCESS_ORIGINAL_IMAGES_UPDATE, payload: data };
}

function userPendingCount(pending: number): UserAction {
	return { type: USER_PENDING_COUNT, payload: pending };
}

function userProcessSuccess(): UserAction {
	return { type: USER_PROCESS_SUCCESS };
}

export function clearUser(): UserAction {
	return { type: USER_CLEAR };
}

function userForm_setStatus(status: UserStatus): UserAction {
	return { type: USER_FORM_STATUS, payload: status };
}

function userForm_setLanguage(language: SupportedLanguage): UserAction {
	return { type: USER_FORM_LANGUAGE, payload: language };
}

function userForm_setMessage(message: string): UserAction {
	return { type: USER_FORM_MESSAGE, payload: message };
}

function userForm_setMessageKeys(message: {
	[key in MessageType]?: AdminMessage;
}): UserAction {
	return { type: USER_FORM_MESSAGE_KEYS, payload: message };
}

function userForm_setInvalidImages(images: string[]): UserAction {
	return { type: USER_FORM_INVALID_IMAGES, payload: images };
}

function userForm_setInvalidInfos(infos: string[]): UserAction {
	return { type: USER_FORM_INVALID_INFOS, payload: infos };
}

function userForm_setValidInfos(infos: string[]): UserAction {
	return { type: USER_FORM_VALID_INFOS, payload: infos };
}

function bannedUserLoading(): BannedUserAction {
	return { type: BANNED_USER_LOADING };
}

function bannedUserSuccess(user: AppUser): BannedUserAction {
	return { type: BANNED_USER_SUCCESS, payload: user };
}

function bannedUserError(message: string): BannedUserAction {
	return { type: BANNED_USER_ERROR, payload: message };
}

function updateVerifiedUserStatus(status: verified_status) {
	return { type: USER_VERIFIED_STATUS, payload: status };
}

function userReason(user: AppUser, add: boolean, reason: string) {
	if (add) {
		if (user.verified_reasons.indexOf(reason) === -1)
			user.verified_reasons.push(reason);
	} else {
		if (user.verified_reasons.indexOf(reason) > -1)
			user.verified_reasons.splice(user.verified_reasons.indexOf(reason), 1);
	}
	return { type: USER_REASON_ADD, payload: user };
}

export function setInvalidImage(image: string, valid: boolean) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		let inValidImages = [...(getStore().userProcess.invalid_images ?? [])];
		const existedIndex = inValidImages.indexOf(image);
		const user = { ...getStore().userProcess.user };
		const changeImages = user.images.filter((i) => i.verified === false);
		//Check valid image
		if (valid) {
			if (existedIndex > -1) {
				inValidImages = inValidImages.filter((k) => k !== image);
				dispatch(userReason(user, false, "photos"));
			}
		} else {
			if (existedIndex === -1) {
				inValidImages.push(image);
				if (user.reasons.length) {
					dispatch(userReason(user, true, "photos"));
				}
			}
		}
		if (
			getStore().userProcess.user?.images == null ||
			getStore().userProcess.user?.images.length === 0 ||
			inValidImages.length === getStore().userProcess.user?.images.length
		) {
			dispatch(setInvalidInfo("profile_images", false));
		} else {
			dispatch(setInvalidInfo("profile_images", true));
		}
		dispatch(userForm_setInvalidImages(inValidImages));
		//
		if (inValidImages.length) {
			if (user.status === "verified") {
				if (inValidImages.length === changeImages.length) {
					dispatch(updateVerifiedUserStatus("not_approved"));
				} else if (inValidImages.length === 0) {
					dispatch(updateVerifiedUserStatus("approved"));
				} else if (
					inValidImages.length <= changeImages.length &&
					inValidImages.length !== 0
				) {
					dispatch(setInvalidInfo("verified_some_issues", false));
					dispatch(updateVerifiedUserStatus("some_issues"));
					dispatch(addOrRemoveMessageKey("verified_some_issues", true));
				}
				if (inValidImages.length === user.images.length) {
					dispatch(updateVerifiedUserStatus("not_approved"));
				}
			}
			if (user.status === "pending") {
				if (inValidImages.length === user.images.length) {
					dispatch(userReason(user, true, "photos"));
					dispatch(setInvalidInfo("profile_images", false));
				}
			} else {
				if (inValidImages.length === changeImages.length) {
					dispatch(userReason(user, true, "photos"));
					dispatch(setInvalidInfo("profile_images", false));
					dispatch(setInvalidInfo("verified_some_issues", true));
					dispatch(addOrRemoveMessageKey("verified_some_issues", false));
				}
			}
		} else {
			dispatch(setInvalidInfo("verified_some_issues", true));
			if (user.status === "verified") {
				dispatch(updateVerifiedUserStatus("approved"));
				dispatch(addOrRemoveMessageKey("verified_approved", true));
			}
		}
		// if (user.status !== "pending") {
		// 	if (inValidImages.length) {
		// 		dispatch(userReason(user, true, "photos"));
		// 		dispatch(setInvalidInfo("profile_images", false));
		// 	}
		// }
	};
}

export function setInvalidInfo(info: InfoType, valid: boolean) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		let inValidInfos = [...(getStore().userProcess.invalid_infos ?? [])];
		let validInfo = [...getStore().userProcess.valid_infos];

		const existedIndex = inValidInfos.indexOf(info);
		const validInfoExistedIdx = indexOf(validInfo, info);

		// console.log(
		// 	"existedIndex, validInfoExistedIdx,",
		// 	validInfo,
		// 	existedIndex,
		// 	validInfoExistedIdx,
		// );
		const user = { ...getStore().userProcess.user };
		if (valid) {
			if (validInfoExistedIdx === -1) validInfo.push(info);
			if (existedIndex > -1)
				inValidInfos = inValidInfos.filter((k) => k !== info);
			if (info !== "verification_image") {
				dispatch(
					userReason(user, false, info === "profile_images" ? "photos" : info),
				);
			}
		} else {
			if (validInfoExistedIdx > -1)
				validInfo = validInfo.filter((k) => k !== info);
			if (existedIndex === -1) inValidInfos.push(info);
			if (user && user.reasons && user.reasons.length) {
				if (info !== "verification_image") {
					dispatch(
						userReason(user, true, info === "profile_images" ? "photos" : info),
					);
				}
			}
		}

		// console.log("invalidInfos.length", inValidInfos, validInfo);
		let _status: UserStatus = "verified";

		if (inValidInfos.length > 0) {
			_status = "invalid";
		}

		if (user.status === "verified") {
			_status = "verified";
		}

		if (["name", "verification_image"].every((i) => validInfo.includes(i))) {
			_status = "verified";
		}

		if (inValidInfos.includes("name")) {
			_status = "invalid";
		}

		if (user.status === "pending" && inValidInfos.includes("profile_images")) {
			_status = "invalid";
		}

		// console.log("status", _status, user);
		dispatch(userForm_setStatus(_status));
		if (!user.verification) {
			dispatch(userForm_setStatus("invalid"));
		}
		dispatch(userForm_setInvalidInfos(inValidInfos));
		dispatch(userForm_setValidInfos(validInfo));
		dispatch(addOrRemoveMessageKey(null, null));
	};
}

export function updateLanguage(l: SupportedLanguage) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		const language = l ?? getStore().userProcess.language;
		if (l) dispatch(userForm_setLanguage(l));
		const { messageKeys } = getStore().userProcess;
		let message = Object.keys(messageKeys)
			.sort((ka, kb) => messageKeyValue[kb] - messageKeyValue[ka])
			.map((k) => messageKeys[k][language])
			.join("\n");
		console.log("message =========> ", message);
		dispatch(userForm_setMessage(message));
	};
}

export function addMessageKey(messageKey: string) {
	return addOrRemoveMessageKey(messageKey, true);
}

export function removeMessageKey(messageKey: string) {
	return addOrRemoveMessageKey(messageKey, false);
}

function addOrRemoveMessageKey(messageKey: string, add: boolean) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		let copiedKeys = { ...getStore().userProcess.messageKeys };
		let user = getStore().userProcess.user;
		const language = getStore().userProcess.language;

		if (!user.verification || isEmpty(user.verification)) {
			copiedKeys["verification_image"] = adminMessages["invalid-verification"];
			delete copiedKeys["verified"];
		} else {
			if (messageKey != null) {
				const workingMessage = adminMessages[messageKey];
				if (add) {
					if (workingMessage.type === "profile_images") {
						copiedKeys[workingMessage.id] = workingMessage;
					} else {
						copiedKeys[workingMessage.type] = workingMessage;
					}
				} else {
					if (workingMessage.type === "profile_images") {
						delete copiedKeys[workingMessage.id];
					} else {
						delete copiedKeys[workingMessage.type];
					}
					delete copiedKeys[workingMessage.type];
				}
			}

			// check fields
			const invalid_infos = getStore().userProcess.invalid_infos;
			if (invalid_infos.length > 0) {
				if (invalid_infos.indexOf("name") === -1) {
					delete copiedKeys["name"];
				} else {
					copiedKeys["name"] = adminMessages["invalid-name"];
				}
				if (invalid_infos.indexOf("bio") === -1) {
					delete copiedKeys["bio"];
				} else {
					copiedKeys["bio"] = adminMessages["invalid-bio"];
				}
				if (invalid_infos.indexOf("occupation") === -1) {
					delete copiedKeys["occupation"];
				} else {
					copiedKeys["occupation"] = adminMessages["invalid-occupation"];
				}

				if (invalid_infos.indexOf("age") !== 1) {
					copiedKeys["age"] = adminMessages["too-youngh-photo"];
				} else delete copiedKeys["age"];

				if (invalid_infos.indexOf("verification_image") === -1) {
					delete copiedKeys["verification_image"];
				}

				if (invalid_infos.indexOf("profile_images") === -1) {
					delete copiedKeys["profile_images"];
				}
				if (invalid_infos.indexOf("verified_some_issues") === -1) {
					delete copiedKeys["verified_some_issues"];
				} else {
					copiedKeys["verified_some_issues"] =
						adminMessages["verified_some_issues"];
				}

				if (invalid_infos.indexOf("age") === -1) {
					delete copiedKeys["age"];
				}
				delete copiedKeys["verified"];
				delete copiedKeys["verified_approved"];
			} else {
				copiedKeys = { verified: adminMessages["verified"] };
				dispatch(userForm_setMessage(adminMessages["verified"][language]));
				let userProcess = getStore().userProcess;
				if (userProcess.verified_status === "approved") {
					delete copiedKeys["verified"];
					copiedKeys["verified_approved"] = adminMessages["verified_approved"];
				}
			}
		}
		dispatch(userForm_setMessageKeys(copiedKeys));
		dispatch(updateLanguage(null));
	};
}

export function banUser(type: "banned_underage" | "banned_break") {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		const { language, user } = getStore().userProcess;
		// if (!user.valid_id_status) user["valid_id_status"] = "initial";
		dispatch(userUpdate(user));
		dispatch(userForm_setStatus(type));
		dispatch(userForm_setMessageKeys({ banned: adminMessages["banned"] }));
		dispatch(userForm_setMessage(adminMessages["banned"][language]));
		dispatch(processUser());
	};
}

export function loadUser() {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		dispatch(clearUser());
		dispatch(userLoading());
		try {
			const user = await UserApi.getInvalidUsers();
			const { language, messageKeys } = getStore().userProcess;

			if (user.user_id) {
				user.status = "pending";
				const images = [...user.images];
				let _zIdxCount = 0;
				forEach(images, (i) => {
					i.verified = false;
					if (i.position === 0) _zIdxCount += 1;
				});

				user.images = images
					.filter((img) => img.type === "profile")
					.sort((a, b) => {
						const aPos = a.position ?? 0;
						const bPos = b.position ?? 0;
						return aPos - bPos;
					});

				// if (user.status === "verified") {
				// 	user.images = filter(user.images, (i) => {
				// 		if (_zIdxCount > 1 && i.position === 0 && i.verified) return false;
				// 		else return true;
				// 	});
				// }

				user.verification = images.filter((img) => img.type !== "profile")[0];
				if (messageKeys["verified"]) {
					dispatch(userForm_setMessage(adminMessages["verified"][language]));
				}
				if (!user.verification || isEmpty(user.verification)) {
					let copiedKeys = { ...getStore().userProcess.messageKeys };
					copiedKeys["verification_image"] =
						adminMessages["invalid-verification"];
					delete copiedKeys["verified"];

					dispatch(userForm_setInvalidInfos(["verification_image"]));
					dispatch(
						userForm_setMessage(
							adminMessages["invalid-verification"][language],
						),
					);
					dispatch(userForm_setMessageKeys(copiedKeys));
					dispatch(userForm_setStatus("invalid"));
				}
				const validInfos: InfoType[] = [
					"name",
					"occupation",
					"profile_images",
					"verification_image",
					"bio",
				];
				map(validInfos, (p: InfoType, idx: number) => {
					if (p === "profile_images") {
						if (!user["images"].length) {
							const _idx = indexOf(validInfos, "profile_images");
							if (_idx > -1) validInfos.splice(_idx, 1);
						}
					} else if (p === "verification_image") {
						if (isEmpty(user["verification"])) {
							const _idx = indexOf(validInfos, "verification_image");
							if (_idx > -1) validInfos.splice(_idx, 1);
						}
					} else if (!user[p] || isEmpty(user[p])) {
						const _idx = indexOf(validInfos, p);
						if (_idx > -1) validInfos.splice(_idx, 1);
					}
				});
				dispatch(userForm_setValidInfos(validInfos));
			}
			user["verified_reasons"] = [];
			dispatch(userUpdate(user));
			dispatch(userOriginalImagesUpdate(user.images));
			if (user.images == null || user.images.length === 0) {
				dispatch(setInvalidInfo("profile_images", true));
			}
			const pending = await UserApi.getPendingCount();
			dispatch(userPendingCount(pending));
		} catch (err) {
			dispatch(userError(err.message));
		}
	};
}

export function processUser() {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		dispatch(userLoading());
		try {
			const {
				invalid_images,
				invalid_infos,
				message,
				status,
				user,
				verified_status,
				language,
				valid_infos,
			} = getStore().userProcess;

			const cInvalid_infos = [
				...invalid_infos.filter((i) => i !== "verified_some_issues"),
			];

			const data: any = {
				invalid_images,
				status,
				admin_sms: message,
				user_id: user.user_id,
				invalid_infos: cInvalid_infos,
				reasons: user.verified_reasons,
				valid_id_status: user.valid_id_status,
				valid_infos,
				verified_status,
				language: language,
				is_edit: user.is_edit,
			};

			let hasZPosImg = false;
			forEach(user.images, (i) => {
				if (i.position === 0 && i.verified) {
					hasZPosImg = true;
					return false;
				}
			});
			if (user.status === "pending" && !hasZPosImg) {
				data.images_position = user.images
					.filter((img) => invalid_images.indexOf(img.key) === -1)
					.filter((img) => !(img.position === 0 && img.verified))
					.map<UserImage>((img, idx) => {
						return { key: img.key, position: idx };
					});
			} else {
				data.images_position = user.images
					.filter((img) => invalid_images.indexOf(img.key) === -1)
					.filter((img) => !(img.position === 0 && img.verified))
					.map<UserImage>((img) => {
						return { key: img.key, position: img.position };
					});
			}

			if (user.status !== "verified") {
				data.verified_status = undefined;
			}

			console.log("payload", JSON.stringify(data));

			await UserApi.process(data);
			// dispatch(loadInfos());
			if (invalid_infos.indexOf("verification_image") !== -1) {
				try {
					await UserApi.deleteValidateImage(user.user_id);
				} catch (ignored) {}
			}
			dispatch(userProcessSuccess());
		} catch (error) {
			dispatch(userError(error.message));
		}
	};
}

// export function swapImage(index: number, direction: "back" | "forward") {
// 	return async (
// 		dispatch: ThunkDispatch<{}, {}, AnyAction>,
// 		getStore: () => ApplicationState
// 	) => {
// 		const { user } = getStore().userProcess;
// 		const updatedImages = [...user.images];

// 		if (
// 			(direction == "back" && index == 0) ||
// 			(direction == "forward" && index == updatedImages.length - 1)
// 		)
// 			return;
// 		const swapingIndex = direction == "back" ? index - 1 : index + 1;
// 		if (swapingIndex < 0 || swapingIndex >= updatedImages.length) return;
// 		let currentImg: UserImage = {
// 			...updatedImages[index],
// 			position: swapingIndex,
// 		};
// 		let swapingImg: UserImage = {
// 			...updatedImages[swapingIndex],
// 			position: index,
// 		};
// 		updatedImages[index] = swapingImg;
// 		updatedImages[swapingIndex] = currentImg;
// 		dispatch(userUpdate({ ...user, images: updatedImages }));
// 	};
// }

export function setOriginalImage() {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		const { original_images, user } = { ...getStore().userProcess };
		dispatch(userUpdate({ ...user, images: [...original_images] }));
	};
}

export function setFaceImage(key: string) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		const { user } = { ...getStore().userProcess };
		const imgClone = [...user.images];

		// if (index === 0) return;
		const currentIdx = findIndex(imgClone, (i) => i.key === key);
		let currentImg: UserImage = {
			...imgClone[currentIdx],
			position: 0,
		};

		let swapingImg: UserImage = {
			...imgClone[0],
			position: currentIdx,
		};

		imgClone[currentIdx] = swapingImg;
		imgClone[0] = currentImg;
		dispatch(userUpdate({ ...user, images: imgClone }));
	};
}

export function fetchBannedUser() {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		dispatch(bannedUserLoading());
		try {
			const resp = await UserApi.getBanned();
			if (!isEmpty(resp)) {
				dispatch(bannedUserSuccess(resp));
			} else dispatch(bannedUserSuccess(null));
		} catch (err) {
			dispatch(bannedUserError(err.message));
		}
	};
}

export function updateBannedUserStatus(
	slug: string,
	valid_id_status: valid_id_status,
	status: string,
) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		dispatch(bannedUserLoading());
		try {
			const resp = await UserApi.updateBannedStatus(
				slug,
				valid_id_status,
				status,
			);
			if (resp.message === "Ok") {
				// dispatch(bannedUserUpdate())
				dispatch(fetchBannedUser());
			}
		} catch (err) {
			dispatch(bannedUserError(err.message));
		}
	};
}

function userInspectLoading(): UserInspectAction {
	return { type: USER_INSPECT_LOADING };
}

function userInspectSelect(slug: string): UserInspectAction {
	return { type: USER_INSPECT_SELECTED, payload: slug };
}

function userInspectSuccess(data: AppUserFull): UserInspectAction {
	return { type: USER_INSPECT_SUCCESS, payload: data };
}

function userInspectError(str: string): UserInspectAction {
	return { type: USER_INSPECT_ERROR, payload: str };
}

function userInspectClear(): UserInspectAction {
	return { type: USER_INSPECT_CLEAR };
}

export function selectUserForInspect(slug: string) {
	return (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		// getStore: () => ApplicationState
	) => {
		dispatch(userInspectSelect(slug));
	};
}

export function fetchUserForInspect() {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => ApplicationState,
	) => {
		dispatch(userInspectLoading());
		try {
			const selected = getStore().userInspect.selected;
			if (selected) {
				const resp = await UserApi.getById(selected);
				dispatch(userInspectSuccess(resp));
			}
		} catch (e) {
			message.error(e.message);
			dispatch(userInspectError(e.message));
		}
	};
}

export function clearUserInspect() {
	return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(userInspectClear());
	};
}

// NEW VERIFICATION
// export function
