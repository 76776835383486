/* eslint-disable react-hooks/exhaustive-deps */
import {
	AuditOutlined,
	BugOutlined,
	ClockCircleOutlined, FileDoneOutlined,
	FormOutlined, IdcardOutlined, PoweroffOutlined,
	ReadOutlined, TeamOutlined
} from "@ant-design/icons";
import { Divider, Layout, Menu, Modal } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { logout } from "app-redux/actions/auth";
import { APP_LOGO } from "assets";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ApplicationState } from "schema";
import { checkout, getWorkingHours } from "../../app-redux/actions/shift";
import "./AppHeader.css";

export default function AppHeader() {
	const location = useLocation().pathname;
	const history = useHistory();
	const dispatch = useDispatch();
	const user = useSelector((state: ApplicationState) => state.auth?.user);
	const { currentPart } = useSelector((state: ApplicationState) => state.shift);
	const [selectedMenu, setSelectedMenu] = useState("/b2b");
	const { collapsed } = useSelector((state: ApplicationState) => state.layout)

	useEffect(() => {
		setSelectedMenu(location);
	}, [location]);

	useEffect(() => {
		dispatch(getWorkingHours());
	}, [])

	return (
		<React.Fragment>
			<Layout.Sider
				trigger={null}
				collapsible
				collapsed={collapsed}
				className="site-sider-wrapper">
				<div className="site-logo">
					<img src={APP_LOGO} alt="" />
				</div>

				<Menu
					theme="dark"
					// mode="horizontal"
					className="nav-menu"
					defaultSelectedKeys={[
						location === "" || location === undefined ? "/" : location,
					]}
					// defaultOpenKeys={["/b2b/assets", "/b2b/promotion"]}
					selectedKeys={[selectedMenu]}
					onSelect={({ selectedKeys }) => {
						console.log("selectedKeys", selectedKeys);
						if (selectedKeys.length === 0) return;
						if (selectedKeys.indexOf("/logout") !== -1) {
							Modal.confirm({
								title: "Đăng xuất",
								icon: <PoweroffOutlined />,
								content: "Bạn muốn đăng xuất hệ thống?",
								onOk: () => {
									if (currentPart)
										dispatch(checkout(currentPart.checkin_id))
									dispatch(logout());
									history.push("/logout")
								},
							});
							return;
						}
						history.push(selectedKeys[0].toString());
						setSelectedMenu(selectedKeys[0].toString());
					}}
				>
					<Menu.Item key="/dashboard">
						<TeamOutlined />
						Check User
					</Menu.Item>
					{/* <Menu.Item key="/verification/face-profile-photos">
						<TeamOutlined />
						Face Profile Photos
					</Menu.Item> */}
					{user.role === "super-admin" || user.role === "admin" ? (
						<Menu.Item key="/dashboard/idchecker">
							<IdcardOutlined />
							ID Checker
						</Menu.Item>
					) : (
						<></>
					)}

					{(user.role === "super-admin" || user.role === "admin") && (
						<SubMenu
							icon={<FileDoneOutlined />}
							title="Operator"
							key="submenu/operator"
						>
							<Menu.Item key="/dashboard/employee/analytics">
								<AuditOutlined />
								Operator Report
							</Menu.Item>
							<Menu.Item key="/dashboard/employee/tracking">
								<BugOutlined />
								Operator Tracking
							</Menu.Item>
							<Menu.Item key="/dashboard/employee/verification">
								<ClockCircleOutlined />
								Verification time
							</Menu.Item>
						</SubMenu>
					)}
					{user.role === "super-admin" ? (
						<Menu.Item key="/dashboard/employee">
							<TeamOutlined />
							Team
						</Menu.Item>
					) : (
						<></>
					)}
					{(user.role === "super-admin" || user.role === "admin") && (
						<SubMenu key="submenu/exam" icon={<FormOutlined />} title="Exam">
							<Menu.Item key="/dashboard/exam">
								<ReadOutlined />
								Dashboard
							</Menu.Item>

							<Menu.Item key="/dashboard/exam/result">
								<FileDoneOutlined />
								Result
							</Menu.Item>
						</SubMenu>
					)}

					{user.role === "operator" ? (
						<Menu.Item key="/dashboard/exam/test">
							<FileDoneOutlined />
							Test
						</Menu.Item>
					) : (
						<></>
					)}
					<Menu.Item key="/dashboard/Shift">
						<ClockCircleOutlined />
						Shift
					</Menu.Item>
					<div style={{ flexGrow: 1 }}></div>
					<Divider />
					<Menu.Item key="/logout">
						<PoweroffOutlined />
						Đăng xuất
					</Menu.Item>
				</Menu>
			</Layout.Sider>
		</React.Fragment>
	);
}
