export const LOGS_TRACKING_LOADING = "Logs/Tracking/Loading";
export type LOGS_TRACKING_LOADING = typeof LOGS_TRACKING_LOADING;

export const LOGS_TRACKING_ERROR = "Logs/Tracking/Error";
export type LOGS_TRACKING_ERROR = typeof LOGS_TRACKING_ERROR;

export const LOGS_TRACKING_QUERY = "Logs/Tracking/Query";
export type LOGS_TRACKING_QUERY = typeof LOGS_TRACKING_QUERY;

export const LOGS_TRACKING_UPDATE_NOTES = "Logs/Tracking/Update/Notes";
export type LOGS_TRACKING_UPDATE_NOTES = typeof LOGS_TRACKING_UPDATE_NOTES;

export const LOGS_TRACKING_UPDATE_DATA = "Logs/Tracking/Update";
export type LOGS_TRACKING_UPDATE_DATA = typeof LOGS_TRACKING_UPDATE_DATA;

export const LOGS_TRACKING_CLEAR = "Logs/Tracking/Clear";
export type LOGS_TRACKING_CLEAR = typeof LOGS_TRACKING_CLEAR;

export const LOGS_ANALYTICS_LOADING = "Logs/Analytics/Loading";
export type LOGS_ANALYTICS_LOADING = typeof LOGS_ANALYTICS_LOADING;

export const LOGS_ANALYTICS_ERROR = "Logs/Analytics/Error";
export type LOGS_ANALYTICS_ERROR = typeof LOGS_ANALYTICS_ERROR;

export const LOGS_ANALYTICS_FILTER = "Logs/Analytics/Filter";
export type LOGS_ANALYTICS_FILTER = typeof LOGS_ANALYTICS_FILTER;

export const LOGS_ANALYTICS_UPDATE = "Logs/Analytics/Update";
export type LOGS_ANALYTICS_UPDATE = typeof LOGS_ANALYTICS_UPDATE;

export const LOGS_ANALYTICS_CLEAR = "Logs/Analytics/Clear";
export type LOGS_ANALYTICS_CLEAR = typeof LOGS_ANALYTICS_CLEAR;

export const LOGS_VERIFICATION_TIME_LOADING = "Logs/VerificationTime/Loading";
export type LOGS_VERIFICATION_TIME_LOADING =
	typeof LOGS_VERIFICATION_TIME_LOADING;

export const LOGS_VERIFICATION_TIME_UPDATE = "Logs/VerificationTime/Update";
export type LOGS_VERIFICATION_TIME_UPDATE =
	typeof LOGS_VERIFICATION_TIME_UPDATE;

export const LOGS_VERIFICATION_TIME_ERROR = "Logs/VerificationTime/Error";
export type LOGS_VERIFICATION_TIME_ERROR = typeof LOGS_VERIFICATION_TIME_ERROR;

export const LOGS_VERIFICATION_TIME_CLEAR = "Logs/VerificationTime/Clear";
export type LOGS_VERIFICATION_TIME_CLEAR = typeof LOGS_VERIFICATION_TIME_CLEAR;

export const LOGS_VERIFICATION_PER_MIN_LOADING =
	"Logs/VerificationTimePerMin/Loading";
export type LOGS_VERIFICATION_PER_MIN_LOADING =
	typeof LOGS_VERIFICATION_PER_MIN_LOADING;

export const LOGS_VERIFICATION_PER_MIN_UPDATE =
	"Logs/VerificationTimePerMin/Update";
export type LOGS_VERIFICATION_PER_MIN_UPDATE =
	typeof LOGS_VERIFICATION_PER_MIN_UPDATE;

export const LOGS_VERIFICATION_PER_MIN_ERROR =
	"Logs/VerificationTimePerMin/Error";
export type LOGS_VERIFICATION_PER_MIN_ERROR =
	typeof LOGS_VERIFICATION_PER_MIN_ERROR;

export const LOGS_VERIFICATION_PER_MIN_CLEAR =
	"Logs/VerificationTimePerMin/Clear";
export type LOGS_VERIFICATION_PER_MIN_CLEAR =
	typeof LOGS_VERIFICATION_PER_MIN_CLEAR;
