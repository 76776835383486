import { applyMiddleware, createStore } from "redux";
import logger from "redux-logger";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";
import reducers from "./reducers";

// declare global {
// 	interface Window {
// 		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
// 		__REDUX_DEVTOOLS_EXTENSION__?: any;
// 	}
// }

const middleware: any[] = [thunk, logger];

// process.env.NODE_ENV === "development" && middleware.push(logger);

export const store = createStore(reducers, applyMiddleware(...middleware));

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const enhancer = composeEnhancers(applyMiddleware(...middleware));
// export const store = createStore(reducers, enhancer);
export const persistor = persistStore(store);
