import { store } from "app-redux";
import { signOut } from "app-redux/actions/auth";
import axios, { AxiosRequestConfig, Method } from "axios";
import { KEY_ACCESS_TOKEN } from "config/constants";
import Cookies from "js-cookie";
import qs from "qs";

export type ErrorDescription = Error & {
	errors: { [key: string]: Array<string> };
};

export const ApiHeader = {
	Authorization: "Bearer " + Cookies.get(KEY_ACCESS_TOKEN),
	version: "2.0",
};

export default async function request<T>(
	method: Method,
	url: string,
	data?: any,
) {
	const headers = {};

	const token = Cookies.get(KEY_ACCESS_TOKEN);
	if (token !== undefined) {
		headers["Authorization"] = "Bearer " + token;
	}

	const config: AxiosRequestConfig = {
		method,
		headers,
		url,
		paramsSerializer: (params) =>
			qs.stringify(params, {
				indices: false,
				skipNulls: true,
			}),
	};

	if (method === "GET" || method === "get") config.params = data;
	else config.data = data;

	try {
		const response = await axios.request(config);
		if (response && response.data) return response.data as T;
	} catch (err) {
		if (err.response.status === 401) {
			store.dispatch(signOut());
			throw new Error(err.response.data["errors"][0]);
		}
		if (
			err.response.data["errors"] != undefined &&
			err.response.data["errors"][0] != undefined
		) {
			throw new Error(err.response.data["errors"][0]);
		}
		throw err;
	}
}
