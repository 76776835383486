import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { ApplicationState } from "schema";
import { authReducer } from "./auth";
import { employeeListReducer, employeeReducer } from "./employee";
import {
	examListReducer,
	examProcessReducer,
	examProfileListReducer,
	examProfileReducer,
	examResultDetailReducer,
	examResultListReducer,
	examResultReducer,
} from "./exam";
import { infoReducer } from "./info";
import { layoutReducer } from "./layout";
import {
	employeeReportReducer,
	employeeTrackingReducer,
	trackingPerMinReducer,
	verificationTimeReducer,
} from "./logs";
import { searchUserReducer } from "./searchUser";
import { shiftReducer } from "./shift";
import { bannedUserReduccer, userInspectReducer, userReducer } from "./user";
import { userVerificationReducer } from "./verification-user";

const storageKey = "fika-admin-1.0";
const reducers = combineReducers<ApplicationState>({
	auth: authReducer,
	userProcess: userReducer,
	infoProcess: infoReducer,
	employee: employeeReducer,
	employeeList: employeeListReducer,
	employeeLogs: employeeTrackingReducer,
	employeeReport: employeeReportReducer,
	searchUsers: searchUserReducer,
	bannedUser: bannedUserReduccer,
	userInspect: userInspectReducer,
	examProfile: examProfileReducer,
	examProfileList: examProfileListReducer,
	examList: examListReducer,
	examProcess: examProcessReducer,
	examResult: examResultReducer,
	examResultList: examResultListReducer,
	examResultDetail: examResultDetailReducer,
	verificationTime: verificationTimeReducer,
	trackingPerMin: trackingPerMinReducer,
	shift: shiftReducer,
	layout: layoutReducer,
	userVerification: userVerificationReducer,
});

export default persistReducer<ApplicationState>(
	{
		key: storageKey,
		storage: storageSession,
		whitelist: ["auth"],
	},
	reducers,
);
