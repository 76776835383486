import { AdminMessage, MessageType } from "schema";

export const messageKeyValue: { [key in MessageType]: number } = {
	verified: 99,
	banned: 98,
	verification_image: 9,
	profile_images: 8,
	info: 7,
	bio: 6,
	name: 5,
	occupation: 4,
	age: 3,
	verified_some_issues: 2,
	verified_approved: 1,
};

export const adminMessages: { [key: string]: AdminMessage } = {
	verified: {
		id: "verified",
		type: "verified",
		description: "Verified (duyệt ảnh và thông tin)",
		vi: "Bạn gì ơi, ảnh profile của bạn đã được duyệt rồi nè!",
		en: "Hey there, you profile photo is now verified.",
	},
	verified_some_issues: {
		id: "verified_some_issues",
		type: "verified_some_issues",
		description: "Verified (một số hình không hợp lệ)",
		vi: "Đừng quên đọc các yêu cầu về hình ảnh, chúng tôi vẫn sẽ phê duyệt các hình ảnh mới.",
		en: "Make sure to read our image guidelines, we still approved some of the new things.",
	},
	verified_approved: {
		id: "verified_approved",
		type: "verified_approved",
		description: "Verified (hình mới tải lên hợp lệ)",
		vi: "Chuẩn không cần chỉnh, profile của bạn đã được cập nhật!",
		en: "Looking good and fresh, nice update!",
	},
	"social-media": {
		id: "social-media",
		type: "info",
		description:
			"Bio, tên, tuổi, nghề nghiệp có nội dung phản cảm, bậy bạ, trùng lặp, không đúng với ảnh",
		vi: "Profile của bạn có chứa thông tin không hợp lệ. Hãy cập nhật lại thông tin khác nhé!",
		en: "Your profile contains irrelevant information. Please update your profile with other photos!",
	},
	"invalid-name": {
		id: "invalid-name",
		type: "name",
		description: "Tên không hợp lệ",
		vi: "Tên của bạn không hợp lệ. Vui lòng chọn tên hợp lệ",
		en: "Your profile name is invalid. Please update your profile name.",
	},
	"invalid-bio": {
		id: "invalid-bio",
		type: "bio",
		description: "Bio không hợp lệ",
		vi: "Bio của bạn không hợp lệ. Vui lòng chọn Bio hợp lệ",
		en: "Your bio photo is invalid. Please update your profile bio.",
	},
	"invalid-occupation": {
		id: "invalid-occupation",
		type: "occupation",
		description: "Nghề nghiệp không hợp lệ",
		vi: "Nghề nghiệp của bạn không hợp lệ. Vui lòng chọn Nghề nghiệp hợp lệ",
		en: "Your occupation photo is invalid. Please update your profile occupation.",
	},
	"invalid-verification": {
		id: "invalid-verification",
		type: "verification_image",
		description: "Ảnh xác minh không hợp lệ.",
		vi: "Ảnh xác minh không hợp lệ. Vui lòng chụp lại ảnh xác minh.",
		en: "Your verification photo is invalid. Please retake verification photo.",
	},
	"dark-verification": {
		id: "dark-verification",
		type: "verification_image",
		description: "Ảnh xác minh quá tối.",
		vi: "Ảnh xác minh quá tối. Vui lòng chụp lại ảnh xác minh.",
		en: "Your verification photo is too dark. Please retake verification photo.",
	},
	"blurry-verification": {
		id: "blurry-verification",
		type: "verification_image",
		description: "Ảnh xác minh quá mờ.",
		vi: "Ảnh xác minh quá mờ. Vui lòng chụp lại ảnh xác minh.",
		en: "Your verification photo is too blurry. Please retake verification photo.",
	},
	"bright-verification": {
		id: "bright-verification",
		type: "verification_image",
		description: "Ảnh xác minh quá sáng.",
		vi: "Ảnh xác minh quá sáng. Vui lòng chụp lại ảnh xác minh.",
		en: "Your verification photo is too bright. Please retake verification photo.",
	},
	"faceless-verification": {
		id: "faceless-verification",
		type: "verification_image",
		description: "Ảnh xác minh không rõ mặt.",
		vi: "Ảnh xác minh không rõ mặt. Vui lòng chụp lại ảnh xác minh.",
		en: "Your verification photo is too faceless. Please retake verification photo.",
	},
	"screenshot-verification": {
		id: "screenshot-verification",
		type: "verification_image",
		description: "Ảnh xác minh là ảnh chụp màn hình.",
		vi: "Ảnh xác minh là ảnh chụp màn hình. Vui lòng chụp lại ảnh xác minh.",
		en: "Your verification photo is screenshot. Please retake verification photo.",
	},
	"original-verification": {
		id: "original-verification",
		type: "verification_image",
		description: "Ảnh xác minh không chính chủ.",
		vi: "Ảnh xác minh là ảnh không chính chủ. Vui lòng chụp lại ảnh xác minh.",
		en: "Your verification photo is not the original owner. Please retake verification photo.",
	},
	banned: {
		id: "banned",
		type: "banned",
		description: "Người tham gia chưa đủ 18 tuổi",
		vi: "Hình ảnh của bạn cho thấy bạn có độ tuổi không phù hợp. Bạn phải trên 18 tuổi mới được sử dụng Fika.",
		en: "It looks like you are under 18 years old. You must be over 18 years old to be a Fika user.",
	},
	"too-many-filter": {
		id: "too-many-filter",
		type: "profile_images",
		description: "Ảnh profile - Ảnh bị filter nhiều che mất mặt",
		vi: "Ảnh sử dụng quá nhiều hiệu ứng chỉnh sửa, không tương khớp với ảnh xác minh.",
		en: "Your profile photo has too much filter on, it does not match with the verification photo.",
	},
	"low-quality": {
		id: "low-quality",
		type: "profile_images",
		description: "Ảnh profile - Ảnh rung mờ tối chất lượng thấp",
		vi: "Ảnh profile chất lượng quá thấp: quá mờ hoặc quá tối. Bạn hãy cập nhật lại ảnh có chất lượng tốt hơn nhé.",
		en: "Your profile photo is low quality: too dark or too blurry. Please upload a better photo!",
	},
	"landscape-photo": {
		id: "landscape-photo",
		type: "profile_images",
		description: "Ảnh profile - Ảnh ngang/landscape",
		vi: "Ảnh profile sử dụng là ảnh ngang. Bạn hãy cập nhật lại ảnh profile chỉ sử dụng ảnh dọc nhé!",
		en: "Your profile photo is horizontal. Please update your profiles by uploading vertical photos only.",
	},
	"no-face-detected": {
		id: "no-face-detected",
		type: "profile_images",
		description: "Ảnh profile - Không có ảnh nào rõ mặt",
		vi: "Ảnh profile của bạn không có ảnh rõ mặt. Bạn hãy cập nhật lại ít nhất 1 ảnh rõ mặt nhé!",
		en: "Your profile does not contain a full face photo. Please update your profile with at least 1 clear face photo!",
	},
	"sexual-drug": {
		id: "sexual-drug",
		type: "profile_images",
		description:
			"Ảnh profile - Ảnh có xuất hiện súng ống, ảnh quá sexy, ảnh xuất hiện đồ chất cấm (cần sa, boong, thuốc lào, bóng cười..), ảnh có em bé",
		vi: "Ảnh profile của bạn có chứa nội dung phản cảm, không phù hợp. Bạn hãy sử dụng ảnh khác thay thế nhé! ",
		en: "Your profile photo contains vulgar content. Please update your profile with other photos!",
	},
	"fake-photos": {
		id: "fake-photos",
		type: "profile_images",
		description: "Ảnh profile - Dùng ảnh fake, ảnh diễn viên, ảnh người khác",
		vi: "Bạn đang sử dụng ảnh không phải ảnh bản thân.",
		en: "You are using somebody's else photos.",
	},
	"comic-photos": {
		id: "comic-photos",
		type: "profile_images",
		description:
			"Ảnh profile - Ảnh comic, ảnh random object, ảnh bán đồ, quảng cáo sản phẩm",
		vi: "Ảnh profile của bạn có chứa nội dung không phù hợp. Bạn hãy sử dụng ảnh khác thay thế nhé!",
		en: "Your profile photo contains inappropriate content. Please update your profile with other photos!",
	},
	"repeated-picture": {
		id: "repeated-picture",
		type: "profile_images",
		description: "Ảnh profile - Ảnh bị lặp",
		vi: "Hình của bạn bị lặp rồi, vui lòng chọn hình khác nhé!",
		en: "Seems like you have uploaded repeated photos, please choose another one.",
	},
	"group-photo": {
		id: "group-photo",
		type: "profile_images",
		description:
			"Ảnh profile - Chỉ có ảnh chụp với bạn bè hội nhóm không có ảnh chụp riêng",
		vi: "Ảnh bạn chọn không thể hiện được bạn là ai. Vui lòng chọn thêm ảnh trong đó chỉ có bạn trong ảnh.",
		en: "The photos you chose don’t represent who you are. Please choose photos that have only you in it.",
	},
	"too-youngh-photo": {
		id: "too-youngh-photo",
		type: "profile_images",
		description: "Ảnh profile - Người dùng nhìn quá trẻ, mặc quần áo học sinh",
		vi: "Hình ảnh, trang phục bạn sử dụng có thể cho thấy bạn không đủ tuổi. Bạn hãy lựa chọn hình ảnh khác thay thế, nếu bạn dưới 18 tuổi bạn sẽ không thể sử dụng ứng dụng Fika.",
		en: "The photos you use may show that you are underage. Please choose another photos instead, if you are under 18 years old you will not be able to use Fika.",
	},
	"screen-shot": {
		id: "screen-shot",
		type: "profile_images",
		description: "Ảnh profile - Ảnh cắt, ảnh chụp màn hình",
		vi: "Bạn đang sử dụng ảnh chụp màn hình không phù hợp. Bạn vui lòng chọn ảnh được chụp trực tiếp và không phải ảnh chụp màn hình nhé.",
		en: "You are using a screenshot which is not valid. Please choose another photos that are not screenshots.",
	},
};
