export const INFO_LOADING = "Info/Loading";
export type INFO_LOADING = typeof INFO_LOADING;

export const INFO_ERROR = "Info/Error";
export type INFO_ERROR = typeof INFO_ERROR;

export const INFO_UPDATE = "Info/Update";
export type INFO_UPDATE = typeof INFO_UPDATE;

export const INFO_SUCCESS = "Info/Success";
export type INFO_SUCCESS = typeof INFO_SUCCESS;

export const INFO_FORM_UPDATE_NAME = "Info/Form/Name";
export type INFO_FORM_UPDATE_NAME = typeof INFO_FORM_UPDATE_NAME;

export const INFO_FORM_UPDATE_BIO = "Info/Form/Bio";
export type INFO_FORM_UPDATE_BIO = typeof INFO_FORM_UPDATE_BIO;

export const INFO_FORM_UPDATE_OCCUPATION = "Info/Form/Occupation";
export type INFO_FORM_UPDATE_OCCUPATION = typeof INFO_FORM_UPDATE_OCCUPATION;

export const INFO_CLEAR = "Info/Clear";
export type INFO_CLEAR = typeof INFO_CLEAR;


export const INFO_UPDATE_VALID_NAME = "Info/ValidName/Update";
export type INFO_UPDATE_VALID_NAME = typeof INFO_UPDATE_VALID_NAME;

export const INFO_UPDATE_VALID_BIO = "Info/ValidBio/Update";
export type INFO_UPDATE_VALID_BIO = typeof INFO_UPDATE_VALID_BIO;

export const INFO_UPDATE_VALID_OCCUPATION = "Info/ValidOcc/Update";
export type INFO_UPDATE_VALID_OCCUPATION = typeof INFO_UPDATE_VALID_OCCUPATION;
